import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";

import {
  Row,
  Col,
  Button,
  Input,
  Select,
  Divider,
  Spin,
  DatePicker,
  Space,
  notification,
  Tag,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Form from "../../Helper/Form";
import { token, sessionData } from "../../Helper/Session";
import { withRouter } from "react-router-dom";
import moment from "moment";

class AddShippingRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slected_address_user_id: "",
      customer_user: [],
      customer_entity: [],
      address: [],
      isToAddrLoad: false,
      isFromAddrLoad: false,
      isBtnLoad: false,
      items: [],
      isLoadItems: false,
      customer_account: [],
      selectedShipProCustomerAccount: [],
      skus: [],
      qty_visible: "block"
    };
  }
  formRef = React.createRef();
  formRef1 = React.createRef();
  form = React.createRef();
  componentDidMount() {
    fetch(`${BASE_URL}${API.USERLISTING}?user_role=3`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_user: json.data,
          });
        }
      });
    var selectedCAShipPro = localStorage.getItem('selectedCAShipPro');
    selectedCAShipPro = JSON.parse(selectedCAShipPro);
    if (selectedCAShipPro != null) {
      if (!selectedCAShipPro.selectedShipProCustomerAccount && !selectedCAShipPro.selectedShipProCustomerAccount) {
        notification.error({ message: 'Error', description: 'Customer account is not selected! Please select to proceed.', placement: "top" });
      } else {
        this.setState({
          selectedShipProCustomerAccount: selectedCAShipPro.selectedShipProCustomerAccount,
        }, () => {
          this.getMajorAddress(this.state.selectedShipProCustomerAccount.id);
          this.fetchCustomerEntity();
        });
      }
    } else {
      notification.error({ message: 'Error', description: 'Customer account is not selected! Please select to proceed.', placement: "top" });
    }

  }
  fetchCustomerEntity = () => {
    fetch(`${BASE_URL}${API.CUSTOMER_ENTIIY_FOR_CUSTOMER_ACCOUNT}?customer_account=` + this.state.selectedShipProCustomerAccount.id, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_entity: json.data,
          });
        } else {
          this.setState({
            customer_entity: [],
          });
        }
      });
  }
  onFinish = (values) => {
    console.log(values);
    this.setState({ isBtnLoad: true });
    values.customer_account = this.state.selectedShipProCustomerAccount.id;
    values.user_id = sessionData.user_id;
    values.awb_collection_date = moment(values.awb_collection_date).format("YYYY-MM-DD");
    fetch(`${BASE_URL}${API.ADD_SHIPPING_REQUEST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isBtnLoad: false });
        if (json.status === true) {
          notification.success({ message: json.success_msg });
          this.props.history.push("/shipping-request-list");
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  getMajorAddress = (value) => {
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ customer_account_id: value }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            slected_address_user_id: value,
            address: json.data,
          });
        } else {
          this.setState({
            address: [],
          });
        }
      });

    fetch(`${BASE_URL}${API.ITEM_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: value }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            items: json.data,
          });
        } else {
          this.setState({
            items: [],
          });
        }
      });
  };

  handleShipToAddress = (value) => {
    this.setState({ isToAddrLoad: true });
    var filteredAddr = this.state.address.filter(function (el) {
      return el.id == value;
    });
    var filteredAddress = filteredAddr[0];
    if (filteredAddr.length != 0) {
      this.formRef.current.setFieldsValue({
        to_company_name: filteredAddress.company_name,
        to_contact_name: filteredAddress.contact_name,
        to_contact_number: filteredAddress.mobile,
        to_email: filteredAddress.email,
        to_mobile: filteredAddress.mobile,
        to_street: filteredAddress.street,
        to_city: filteredAddress.city,
        to_state: filteredAddress.state,
        to_country: filteredAddress.country,
        to_postal: filteredAddress.postal,
        is_shipment_tobe_added_sisl: "NO",
      });
      if (filteredAddress.is_warehouse == 1) {
        this.formRef.current.setFieldsValue({
          is_shipment_tobe_added_sisl: "YES",
        })
      }
    } else {
      this.formRef.current.setFieldsValue({
        to_company_name: "",
        to_contact_name: "",
        to_contact_number: "",
        to_email: "",
        to_mobile: "",
        to_street: "",
        to_city: "",
        to_state: "",
        to_country: "",
        to_postal: "",
        is_shipment_tobe_added_sisl: "NO",
      });
    }
    this.setState({ isToAddrLoad: false });
  };

  handleItemsChange = (key, sku_id) => {
    // this.setState({ isLoadItems: true });

    // this.setState({ isLoadItems: false });
    var sku = this.state.skus.filter(function (el) {
      return el.id == sku_id;
    });
    var sku_detail = sku[0];
    if (sku_id == "customized_box") {
      var itm = {
        item_description: "",
        available_qty: 1000,
      };
      this.setState({
        qty_visible: "none"
      })
    } else {
      var itm = {
        item_description: sku_detail.product_name,
        available_qty: parseInt(sku_detail.available),
        packaging: "No"
      };
      this.setState({
        qty_visible: "block"
      })
    }
    const fields = this.formRef.current.getFieldsValue();
    const { items } = fields;
    Object.assign(items[key], itm);
    this.formRef.current.setFieldsValue({
      items
    });

  };

  handleShipFromAddress = (value) => {
    this.setState({ isFromAddrLoad: true });
    var filteredAddr = this.state.address.filter(function (el) {
      return el.id == value;
    });
    var filteredAddress = filteredAddr[0];
    if (filteredAddr.length != 0) {
      this.formRef.current.setFieldsValue({
        from_company_name: filteredAddress.company_name,
        from_contact_name: filteredAddress.contact_name,
        from_contact_number: filteredAddress.mobile,
        from_email: filteredAddress.email,
        from_mobile: filteredAddress.mobile,
        from_street: filteredAddress.street,
        from_city: filteredAddress.city,
        from_state: filteredAddress.state,
        from_country: filteredAddress.country,
        from_postal: filteredAddress.postal,
        is_shipment_already_held_sisl: "NO"
      });
      if (filteredAddress.is_warehouse == 1) {
        this.formRef.current.setFieldsValue({
          is_shipment_already_held_sisl: "YES"
        })
        var warehouse_id = filteredAddress.id.split("W")[1];
        this.handleChangeSKU(warehouse_id);
      } else {
        this.setState({ skus: [] });
      }
    } else {
      this.formRef.current.setFieldsValue({
        from_company_name: "",
        from_contact_name: "",
        from_contact_number: "",
        from_email: "",
        from_mobile: "",
        from_street: "",
        from_city: "",
        from_state: "",
        from_country: "",
        from_postal: "",
        is_shipment_already_held_sisl: "NO"
      });
    }
    this.setState({ isFromAddrLoad: false });
  };

  fetchProducts = (value) => {
    if (value == "NO") {
      this.setState({ skus: [] });
    } else {
      var collection_address = this.formRef.current.getFieldValue('collection_address');
      this.handleShipFromAddress(collection_address);
    }
  }
  handleChangeSKU = (warehouse_id) => {
    this.setState({ skus: [] });
    const dta = {
      // sku: value,
      warehouse_id: warehouse_id,
      customer_account_id: this.state.selectedShipProCustomerAccount.id
    }
    fetch(`${BASE_URL}${API.GET_SKU}`, {
      method: 'post',
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dta)
    }).then((res) => res.json()).then((json) => {
      if (json.status === true) {
        const skus = [];
        json.data.forEach((r) => {
          skus.push({
            id: r['sku'],
            product_name: r['product_name'] + "-" + r['sku'],
            available: r.available
          });
        });
        this.setState({ skus });
      }
    });
  }
  render() {
    const {
      customer_entity,
      address,
      isToAddrLoad,
      isFromAddrLoad,
      items,
      isBtnLoad,
      customer_account,
      skus,
      selectedShipProCustomerAccount
    } = this.state;

    const { Option } = Select;
    const skus_options = skus.map(d => <Option key={d.id} value={d.id}>{d.product_name}</Option>);
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <Row>
                <Col md={12}><h4>Create "Shipment Instruction"</h4></Col>
                <Col md={12}>
                  <div class="">
                    <h4>Customer Account <Tag color="gold" style={{ fontSize: "16px" }}>{selectedShipProCustomerAccount.customer_account_name}</Tag></h4>
                  </div>
                </Col>
              </Row>

              <Form
                ref={this.formRef}
                name="register"
                layout="vertical"
                onFinish={this.onFinish}
                scrollToFirstError
              >
                <Row>
                  <Col md={12}>
                    <Form.Item
                      name="client_reference_id"
                      label="Client Ref ID/ Client AWB No"
                      tooltip="Enter any specific ID required for internal reference OR leave blank"
                      rules={[
                        {
                          message: "Please enter Client Ref ID/ Client AWB No!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <div className="help">Enter any specific ID required for internal reference OR leave blank</div>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="customer_entity"
                      label="Client billing entity"
                      rules={[
                        {
                          required: true,
                          message: "Please select client billing entity!",
                        },
                      ]}
                    >
                      <Select placeholder="Select Client Billing Entity required for billing">
                        {customer_entity.map((opt) => {
                          return <Option value={opt.id}>{opt.company_name}</Option>;
                        })}
                      </Select>
                      {/* <span className="help">Select Client Billing Entity required for billing</span> */}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="awb_collection_date"
                      label="Instruction Date"
                      rules={[
                        {
                          required: false,
                          message: "Please enter Instruction Date!",
                        },
                      ]}
                    >
                      <DatePicker style={{ width: "100%" }}
                        // onChange={this.onChange}
                        format="DD-MM-YYYY" />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />
                {/* collection address */}
                <Row>
                  <Col md={12}>
                    <Form.Item
                      name="collection_address"
                      label="Ship From' Location"
                      rules={[
                        {
                          required: true,
                          message: "Please select Ship From' Location",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Ship From' Location"
                        onChange={this.handleShipFromAddress}
                      >
                        {address.map((opt) => {
                          return (
                            <Option value={opt.id}>
                              {opt.company_name + (opt.contact_name ? " - " + opt.contact_name : '')}
                            </Option>
                          );
                        })}
                        <Option value="0">New Address</Option>
                      </Select>

                    </Form.Item>
                    <div className="help">Enter the collection address details or select from the drop down options</div>
                  </Col>

                  <Col md={12}>
                    <Form.Item
                      name="from_company_name"
                      label="Company Name"
                      rules={[
                        {
                          required: false,
                          message: "Please enter collection company name!",
                        },
                      ]}
                    >
                      <Input size="" />
                    </Form.Item>
                  </Col>
                </Row>
                <Spin spinning={isFromAddrLoad}>
                  <Row className="ship_address">

                    <Col md={6}>
                      <Form.Item
                        name="from_contact_name"
                        label="Contact Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter collection contact name!",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_contact_number"
                        label="Contact Number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter collection contact number!",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_email"
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "Please enter collection email!",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_street"
                        label="Street"
                        rules={[
                          {
                            required: true,
                            message: "Please enter collection street address!",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_city"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: "Please enter collection city",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_postal"
                        label="Post code"
                        rules={[
                          {
                            required: false,
                            message: "Please enter collection postal address!",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "Please enter collection state",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>

                    <Col md={6}>
                      <Form.Item
                        name="from_country"
                        label="Country"
                        rules={[
                          {
                            required: true,
                            message: "Please enter collection Country",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Spin>
                <Divider />
                <Row>
                  <Col md={12}>
                    <Form.Item
                      name="delivery_address"
                      label="Ship To' Location"
                      rules={[
                        {
                          required: true,
                          message: "Please select Ship To' Location",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Ship To' Location"
                        onChange={this.handleShipToAddress}

                      >
                        {address.map((opt) => {
                          return (
                            <Option value={opt.id}>
                              {opt.company_name + (opt.contact_name ? " - " + opt.contact_name : '')}
                            </Option>
                          );
                        })}
                        <Option value="0">New Address</Option>
                      </Select>

                    </Form.Item>
                    <div className="help">Enter the delivery address details or select from the drop down options</div>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="to_company_name"
                      label="Company Name"
                      rules={[
                        {
                          required: false,
                          message: "Please enter delivery company name!",
                        },
                      ]}
                    >
                      <Input size="" />
                    </Form.Item>
                  </Col>
                </Row>
                <Spin spinning={isToAddrLoad}>
                  <Row className="ship_address">

                    <Col md={6}>
                      <Form.Item
                        name="to_contact_name"
                        label="Delivery: Contact Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter delivery contact name!",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="to_contact_number"
                        label="Contact number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter delivery contact number!",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="to_email"
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "Please enter delivery email!",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="to_street"
                        label="Street"
                        rules={[
                          {
                            required: true,
                            message: "Please enter delivery street address!",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>

                    <Col md={6}>
                      <Form.Item
                        name="to_city"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: "Please enter delivery city",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="to_postal"
                        label="Post code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter delivery postal",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>

                    <Col md={6}>
                      <Form.Item
                        name="to_state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "Please enter delivery state",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>

                    <Col md={6}>
                      <Form.Item
                        name="to_country"
                        label="Country"
                        rules={[
                          {
                            required: true,
                            message: "Please enter delivery Country",
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Spin>
                <Divider />
                <Row>
                  <Col md={12}>
                    <Form.Item
                      name="is_shipment_already_held_sisl"
                      label="Shipping items from stock already held by SISL at warehouse"
                      rules={[
                        {
                          required: true,
                          message: "Please select option!",
                        },
                      ]}
                    >
                      <Select onChange={this.fetchProducts}>
                        <Option value="NO">No</Option>
                        <Option value="YES">Yes</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="is_shipment_tobe_added_sisl"
                      label="Shipped item to be added to stock held at SISL Warehouse"
                      rules={[
                        {
                          required: true,
                          message: "Please select option!",
                        },
                      ]}
                    >
                      <Select>
                        <Option value="NO">No</Option>
                        <Option value="YES">Yes</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Form.List name="items">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key}>
                          <Row>
                            <Divider orientation="left" plain>
                              Item {key + 1}
                            </Divider>
                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "item_id"]}
                                label="Select Product"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select product!",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select item type"
                                  onChange={this.handleItemsChange.bind(
                                    this,
                                    key
                                  )}
                                  style={{
                                    width: "200px",
                                  }}
                                >
                                  {skus_options}
                                  <Option value="customized_box">
                                    New Item
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "item_description"]}
                                label="Item Description"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Item description!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col style={{ display: this.state.qty_visible }}>
                              <Form.Item
                                {...restField}
                                name={[name, "available_qty"]}
                                label="Quantity in Stock"
                              >
                                <Input
                                  style={{
                                    width: "100px",
                                  }}
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "item_quantity"]}
                                label="Item quantity"
                                dependencies={[name, "available_qty"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter item quantity!",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      // console.log(getFieldValue("items")[key]['available_qty']);
                                      if (!value || value <= getFieldValue("items")[key]['available_qty']) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error(
                                          "Quantity can not exeeds from available Quantity!"
                                        )
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  placeholder="Item Quantity"
                                  style={{
                                    width: "150px",
                                  }}
                                />
                              </Form.Item>
                            </Col>


                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "packaging"]}
                                label="Packaging Required before collection"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select option!",
                                  },
                                ]}
                              >
                                <Select>
                                  <Option value="YES">Yes</Option>
                                  <Option value="NO">No</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "other_info"]}
                                label="Other Information"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please enter other information!",
                                  },
                                ]}
                              >
                                <Input placeholder="Other Information" />
                              </Form.Item>
                              <div className="help">(Note - please provide any other information which should be noted before shipping the items such as any specific serial numbers)</div>
                            </Col>

                            <Col>
                              <Form.Item>
                                <Button
                                  type="danger"
                                  onClick={() => remove(name)}
                                  size="medium"
                                  style={{ marginTop: "21px" }}
                                  icon={<MinusCircleOutlined />}
                                ></Button>
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          {/* <PlusCircleOutlined onClick={() => add(name)} /> */}
                        </Space>
                      ))}
                      <Col md={24}>
                        <Form.Item>
                          <Button
                            type="dashed-secondary"
                            size="large"
                            onClick={() => add()}
                            block
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                          >
                            Add Items
                          </Button>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Form.List>

                <Divider />
                <Row>
                  <Col md={12}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={isBtnLoad}
                      >
                        Create Shipment Instruction
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(AddShippingRequest);
