import React from "react";
import Headers from "./component/Headers";
import { Card, Button, Input, message, Form, Select } from "antd";
import { BuildOutlined, ShoppingCartOutlined, FolderAddOutlined, BorderBottomOutlined } from "@ant-design/icons";
import { Col, Row, Steps, Divider, Tag, Modal } from "antd";
import { API, BASE_URL } from "../Endpoint";
import { token, sessionData } from "../Helper/Session";
import { withRouter } from "react-router-dom";
import { Spin, List, Drawer } from 'antd';
const { Step } = Steps;
const { Search } = Input;
class Home extends React.Component {
    state = {
        warehouse: [],
        selectedCustomerAccount: [],
        selectedWareHouse: [],
        selectedShipProCustomerAccount: [],
        customer_account: [],
        openSelectCustomerAccount: false,
        openSelectCustomerAccountWareHouse: false,
        redirect_value: "",
        tasks: []
    };
    formRefShip = React.createRef();
    formRef = React.createRef();
    handleTracking = (value) => {
        this.setState({ isSearching: true, stepperDisplay: "hide_box" });
        fetch(`${BASE_URL}${API.SHIPMENT_TRACKING}`, {
            method: "POST",
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                tracking_id: value,
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({ isSearching: false });
                if (json.status == true) {
                    this.setState({
                        track_data: json.data,
                        request_shipping_status: json.data.shipping_status,
                        stepperDisplay: "show_box",
                    });
                    // this.props.history.push("/shipping-request/view/" + json.data.id);
                } else {
                    message.error({
                        content: json.error_msg,
                        duration: 2,
                    });
                }
            });
    };
    componentDidMount() {
        /*check warehouselocation & customer account selected or not*/
        var selectedWareCA = localStorage.getItem('selectedWareCA');
        selectedWareCA = JSON.parse(selectedWareCA);
        if (selectedWareCA != null) {
            this.setState({
                selectedCustomerAccount: selectedWareCA.selectedCustomerAccount,
                selectedWareHouse: selectedWareCA.selectedWareHouse
            })
        }
        var selectedCAShipPro = localStorage.getItem('selectedCAShipPro');
        selectedCAShipPro = JSON.parse(selectedCAShipPro);
        if (selectedCAShipPro != null) {
            this.setState({
                selectedShipProCustomerAccount: selectedCAShipPro.selectedShipProCustomerAccount,
            })
        }
        /* //check warehouselocation & customer account selected or not*/
        this.fetchPendingTask();
    }

    fetchPendingTask = () => {
        // e.preventDefault();
        fetch(`${BASE_URL}${API.GET_PENDING_TASK}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {

                this.setState({
                    tasks: json.data,
                });

            });
    }
    fetchSelectCustomerForShipPro = () => {
        // e.preventDefault();
        fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        customer_account: json.data,
                    });
                }
            });
        this.setState({ openSelectCustomerAccount: true }, () => {
            this.formRefShip.current.setFieldsValue({
                customer_account: this.state.selectedShipProCustomerAccount.id
            })
        });
    }
    fetchSelectCustomerWarehouse = (v) => {
        this.setState({ redirect_value: v })
        fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        customer_account: json.data,
                    }, () => {
                        this.formRef.current.setFieldsValue({
                            customer_account: this.state.selectedCustomerAccount.id
                        })
                        this.fetchSelectCustomerWarehouseForCustomerAccount(this.state.selectedCustomerAccount.id);
                    });
                }
            });
        this.setState({ openSelectCustomerAccountWareHouse: true });
        this.formRef.current.setFieldsValue({
            customer_account: this.state.selectedCustomerAccount.id
        })
    }
    fetchSelectCustomerWarehouseForCustomerAccount = (customer_account_id) => {
        fetch(`${BASE_URL}${API.WAREHOUSE}?customer_account_id=` + customer_account_id, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        warehouse: json.data,
                    });
                    this.formRef.current.setFieldsValue({
                        warehouse: this.state.selectedWareHouse.id,
                    })
                } else {
                    this.setState({
                        warehouse: [],
                    });
                    this.formRef.current.setFieldsValue({
                        warehouse: "",
                    })
                }
            });
    }
    handleSelectShipProCustomerAccount = (value) => {
        let CustomerAccountArray = this.state.customer_account.filter(function (ca) {
            return ca.id == value.customer_account;
        });
        this.setState({
            selectedShipProCustomerAccount: CustomerAccountArray[0],
            openSelectCustomerAccount: false,
        })
        const storageDta = {
            selectedShipProCustomerAccount: this.state.selectedShipProCustomerAccount,
            user_id: sessionStorage.id
        }
        localStorage.setItem("selectedCAShipPro", JSON.stringify(storageDta));
        // if (window.location.pathname.match("/shipping-request-list") || window.location.pathname.match("/add-shipping-request")) {
        //   window.location.reload();
        //   // alert();
        // }
        this.props.history.push("/add-shipping-request")
    }
    handleSelectCustomerAccount = (value) => {
        let warehouseArray = this.state.warehouse.filter(function (el) {
            return el.id == value.warehouse;
        });
        let CustomerAccountArray = this.state.customer_account.filter(function (ca) {
            return ca.id == value.customer_account;
        });
        this.setState({
            selectedWareHouse: warehouseArray[0],
            selectedCustomerAccount: CustomerAccountArray[0],
            openSelectCustomerAccountWareHouse: false,
        })
        const storageDta = {
            selectedWareHouse: this.state.selectedWareHouse,
            selectedCustomerAccount: this.state.selectedCustomerAccount,
            user_id: sessionStorage.id
        }
        localStorage.setItem("selectedWareCA", JSON.stringify(storageDta));
        // if (window.location.pathname == "/cart-out" || window.location.pathname == "/cart-in" || window.location.pathname == "/inventory") {
        //     window.location.reload();
        // }
        this.props.history.push(this.state.redirect_value);
    }

    mark_as_done = (value) => {
        // alert(value)
        fetch(`${BASE_URL}${API.MARK_AS_DONE}?module_id=` + value, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.fetchPendingTask();
                }
            });

    }
    render() {
        const {
            tasks,
            selectedShipProCustomerAccount,
            openSelectCustomerAccountWareHouse,
            products,
            customer_account,
            openSelectCustomerAccount,
            warehouse
        } = this.state;
        const { Option } = Select;
        // const data = [
        //     'Task 1 - Pending since 1 week',
        //     'Task 2 - Pending since 2 week',
        //     'Task 3 - Pending since 3 week'

        // ];
        return (
            <Headers
                bodyContain={
                    <>
                        <Row>
                            {(sessionData.role_id != 5) && (
                                <Col md={12}>
                                    <div class="dashboard_box">
                                        <Col md={24}>
                                            <div class="summary_box">
                                                <Row>
                                                    <Col md={24}>
                                                        <p className="box_title"><ShoppingCartOutlined /> Warehouse Pro</p>
                                                    </Col>
                                                    <Col md={24}>
                                                        <div style={{ marginTop: "20px" }}>
                                                            <p> Selected Customer Account: <Tag color="orange">{(this.state.selectedCustomerAccount.customer_account_name) ? this.state.selectedCustomerAccount.nickname : 'None'}</Tag></p>
                                                        </div>
                                                    </Col>
                                                    <Col md={24}>
                                                        <div style={{ marginTop: "20px" }}>
                                                            <p> Selected Warehouse: <Tag color="purple">{(this.state.selectedWareHouse.location_name) ? this.state.selectedWareHouse.location_name : 'None'}</Tag></p>
                                                        </div>
                                                    </Col>
                                                    {(sessionData.role_id != 6) && (
                                                        <>
                                                            <Col md={24}>
                                                                <div style={{ marginTop: "20px" }}>
                                                                    <Button
                                                                        style={{ width: "100%", background: "bottom" }}
                                                                        onClick={() => this.fetchSelectCustomerWarehouse("cart-in")}
                                                                        type="warning"
                                                                    >
                                                                        Create Goods-In Instruction
                                                                    </Button>
                                                                </div>
                                                            </Col>

                                                            <Col md={24}>
                                                                <div style={{ marginTop: "20px" }}>
                                                                    <Button
                                                                        style={{ width: "100%", background: "#f4feff", borderColor: "#f4feff" }}
                                                                        onClick={() => this.fetchSelectCustomerWarehouse("cart-out")}
                                                                        type=""
                                                                    >
                                                                        Create Goods-Out Instruction
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>)}
                            {(sessionData.role_id != 6) && (
                                <Col md={12}>
                                    <div className="dashboard_box shippro_">
                                        <Col md={24}>
                                            <div class="summary_box">
                                                <Row style={{ "width": "100%" }}>
                                                    <Col md={24}>
                                                        <p className="box_title"><BuildOutlined /> Ship Pro</p>
                                                    </Col>
                                                    <Col md={24}>
                                                        <div style={{ marginTop: "20px" }}>
                                                            <p> Selected Customer Account: <Tag color="orange">{(this.state.selectedShipProCustomerAccount.customer_account_name) ? this.state.selectedShipProCustomerAccount.nickname : 'None'}</Tag></p>
                                                        </div>
                                                    </Col>
                                                    <Col md={24}>
                                                        <div style={{ marginTop: "60px" }}>
                                                            <Button
                                                                type=""
                                                                style={{ width: "100%", background: "#fffbef" }}
                                                                // onClick={() =>
                                                                // this.props.history.push("/add-shipping-request")
                                                                // }
                                                                onClick={() => this.fetchSelectCustomerForShipPro()}
                                                            >
                                                                Create Shipment Instruction
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>)}
                            <Col md={24}>
                                <div class="dashboard_box pending_task">
                                    <Col md={24}>
                                        <div class="summary_box summary_box_">
                                            <Row>
                                                <Col md={24}>
                                                    <p className="box_title"><BorderBottomOutlined />  Pending Tasks</p>
                                                </Col>
                                                <Col md={24}>
                                                    <List
                                                        size="small"
                                                        // header={<div>Header</div>}
                                                        // footer={<div>Footer</div>}
                                                        bordered
                                                        dataSource={tasks}
                                                        renderItem={(item, i) => <List.Item><a href={item.site_url}>{'Task ' + (i + 1)}</a> <Tag color="orange" style={{ "marginLeft": "20px" }}> {item.label + ' Instruction Id - ' + item.instruction_id + ' Pending from ' + item.created_at}</Tag><a className="mark_as_done" onClick={() => this.mark_as_done(item.id)}>Mark as done</a></List.Item>}
                                                        style={{ "marginTop": "20px", "marginBottom": "20px", backgroundColor: "rgb(255, 251, 239)" }}
                                                    />
                                                </Col>

                                                {/* <Col md={24}>
                                                    <div style={{ marginTop: "15px" }}>
                                                        <Button
                                                            style={{ width: "100%", background: "rgb(255, 251, 239)", color: "#000" }}
                                                            onClick={() => this.fetchSelectCustomerWarehouse("cart-in")}
                                                            type="warning"
                                                        >
                                                            View all
                                                        </Button>
                                                    </div>
                                                </Col> */}
                                            </Row>
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                            <Col md={24} >
                                <Card className="about_tool">
                                    <Row>
                                        <Col>
                                            <h3 style={{ fontWeight: "500", marginLeft: "13px" }}> About SG Pro Tool</h3> </Col>
                                        <Col span={12} offset={8}>
                                            <div style={{ textAlign: "center", float: "right" }}>
                                                <Button
                                                    type="grad"
                                                    style={{ width: "300px", backgroundImage: "linear-gradient(90deg, #739dc3, #567087);" }}
                                                    onClick={""}
                                                >
                                                    View User Guide
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Card type="inner" className="about_tool" style={{ marginTop: "20px", background: "#f5faff" }}>
                                        SG pro tool is designed to provide information regarding shipping and stocking of the goods in SISL global. Users receives constant updates and responsive support via the tool.
                                    </Card>

                                </Card>
                            </Col>
                        </Row>
                        <Modal
                            title="Select Customer Account & WareHouse"
                            style={{ top: 20 }}
                            visible={openSelectCustomerAccountWareHouse}
                            width={600}
                            onCancel={() => this.setState({ openSelectCustomerAccountWareHouse: false })}
                            footer={[
                                <Button
                                    key="cancel"
                                    type="default"
                                    loading=""
                                    onClick={() => this.setState({ openSelectCustomerAccountWareHouse: false })}
                                >
                                    Cancel
                                </Button>,
                            ]}>
                            <Form
                                name="select_customer_account_warehouse"
                                layout="vertical"
                                ref={this.formRef}
                                onFinish={this.handleSelectCustomerAccount}
                                scrollToFirstError
                            >
                                <Row>
                                    <Col md={24}>
                                        <Form.Item
                                            name="customer_account"
                                            label="Customer account"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select customer account name!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                allowClear={true}
                                                onChange={this.fetchSelectCustomerWarehouseForCustomerAccount}
                                            >
                                                {customer_account.map((ca) => {
                                                    return (
                                                        <Option key={ca.id} value={ca.id}>
                                                            {ca.customer_account_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="warehouse"
                                            label="Warehouse"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select warehouse!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                allowClear={true}
                                            >
                                                {warehouse.map((opt) => {
                                                    return (
                                                        <Option value={opt.id}>{opt.location_name}</Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24}>
                                        <Form.Item>
                                            <Button
                                                type="info"
                                                htmlType="submit"
                                                size="medium"
                                                loading={false}
                                            >
                                                Continue
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                        <Modal
                            title="Select Customer Account"
                            style={{ top: 20 }}
                            visible={openSelectCustomerAccount}
                            width={600}
                            onCancel={() => this.setState({ openSelectCustomerAccount: false })}
                            footer={[
                                <Button
                                    key="cancel"
                                    type="default"
                                    loading=""
                                    onClick={() => this.setState({ openSelectCustomerAccount: false })}
                                >
                                    Cancel
                                </Button>,
                            ]}>
                            <Form
                                name="select_customer_account_warehouse"
                                layout="vertical"
                                ref={this.formRefShip}
                                onFinish={this.handleSelectShipProCustomerAccount}
                                scrollToFirstError
                            >
                                <Row>
                                    <Col md={24}>
                                        <Form.Item
                                            name="customer_account"
                                            label="Customer account"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select customer account name!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                allowClear={true}
                                            >
                                                {customer_account.map((ca) => {
                                                    return (
                                                        <Option key={ca.id} value={ca.id}>
                                                            {ca.customer_account_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24}>
                                        <Form.Item>
                                            <Button
                                                type="info"
                                                htmlType="submit"
                                                size="medium"
                                                loading={false}
                                            >
                                                Continue
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    </>
                }
            />
        );
    }
}
export default withRouter(Home);
