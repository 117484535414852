import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import Module from "../Pages/Module/Module";
import AddUser from "../Pages/users/adduser";
import AddCustomerUser from "../Pages/users/add-customer-user";
import Login from "../Pages/Login";
import AddShippingRequest from "../Pages/Shipping/add-request";
import CustomerAccount from "../Pages/CustomerAccount";
import AddCustomerAccount from "../Pages/CustomerAccount/addCustomerAccount";
import CustomerUserList from "../Pages/users/customer-user-list";
import EditCustomerUser from "../Pages/CustomerUser/edit-customer-user";
import ShippingRequestList from "../Pages/Shipping/index";
import AllUsersListing from "../Pages/users/all-users";
import ViewShippingRequest from "../Pages/Shipping/view";
import EditCustomerAccount from "../Pages/CustomerAccount/edit-customer-account";
import EditUsers from "../Pages/users/EditUsers";
import Tracking from "../Pages/Shipping/tracking";
import EditShippingRequest from "../Pages/Shipping/edit-request";
import MyAddress from "../Pages/Shipping/my-address";
import OmsSo from "../Pages/oms-so";
import OmsSoDetail from "../Pages/component/oms-so-detail";
import AddSislEntity from "../Pages/sisl-entity/add-sisl-entity";
import AddCustomerEntity from "../Pages/sisl-entity/add-customer-entity";
import EntityList from "../Pages/sisl-entity/list";
import CustomerEntityList from "../Pages/sisl-entity/customer-entity-list";
import AddWarehouse from "../Pages/warehouse/add";
import Warehouse from "../Pages/warehouse/index";
import Products from "../Pages/product";
import CartIn from "../Pages/cart/cart_in";
import CartOut from "../Pages/cart/cart_out";
import { sessionData } from "../Helper/Session";
import NotFound from "../Pages/component/NotFound";
import SISLUSERS from "../Pages/users/warehouse-operators";
import AccountManagers from "../Pages/users/account-managers"
import WareHouseClients from "../Pages/warehouse/customers";
import Inventory from "../Pages/inventory";
import LogisticOpearator from "../Pages/users/logistic-opearator";
import EditProcessShippingInstruction from "../Pages/Shipping/EditProcessShippingInstruction"
import FinanceUser from "../Pages/users/finance-user";
import Search_SKU from "../Pages/inventory/search";
import WAREHOUSE_OPERATOR_MEMBER from "../Pages/users/member";

const Routers = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route path="/module">
          <Module />
        </Route>
        <Route exact path="/cart-in">
          <CartIn />
        </Route>
        <Route exact path="/cart-out">
          <CartOut />
        </Route>
        <Route exact path="/inventory">
          <Inventory />
        </Route>
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>
        <Route
          exact
          path="/oms-so-detail/:id"
          render={(props) => {
            return <OmsSoDetail id={props.match.params?.id} />;
          }}
        />
        <Route path="/add-shipping-request">
          <AddShippingRequest />
        </Route>
        <Route path="/shipping-request-list">
          <ShippingRequestList />
        </Route>

        <Route path="/shipping-so">
          <OmsSo />
        </Route>
        <Route path="/user/my-address">
          <MyAddress />
        </Route>
        <Route
          path="/shipping-request/view/:id"
          render={(props) => {
            return <ViewShippingRequest id={props.match.params?.id} />;
          }}
        />
        {/* check session and only accesible to admin */}
        {sessionData.role_id == 1 && (
          <>
            {/* <Route exact path="/product">
              <Products />
            </Route> */}
            <Route exact path="/all-users">
              <AllUsersListing />
            </Route>
            <Route path="/add-user">
              <AddUser />
            </Route>
            <Route path="/add-customer-user">
              <AddCustomerUser />
            </Route>
            <Route path="/customer-users">
              <CustomerUserList user_role={4} />
            </Route>
            <Route exact path="/account-managers">
              <AccountManagers user_role="2" />
            </Route>
            <Route exact path="/warehouse-operators">
              <SISLUSERS user_role="3" />
            </Route>
            <Route exact path="/logistic-operators">
              <LogisticOpearator />
            </Route>
            <Route path="/customer-account">
              <CustomerAccount />
            </Route>
            <Route exact path="/add-sisl-entity">
              <AddSislEntity />
            </Route>
            <Route exact path="/add-customer-entity">
              <AddCustomerEntity />
            </Route>
            <Route exact path="/add-customer-account">
              <AddCustomerAccount />
            </Route>
            <Route exact path="/warehouse">
              <Warehouse />
            </Route>
            <Route path="/sisl-entity">
              <EntityList />
            </Route>
            <Route path="/customer-entity">
              <CustomerEntityList />
            </Route>
            <Route path="/warehouse/add">
              <AddWarehouse />
            </Route>
            <Route path="/finance-users">
              <FinanceUser user_role={6} />
            </Route>
            <Route
              path="/warehouse/edit/:id"
              render={(props) => {
                return <AddWarehouse id={props.match.params?.id} />;
              }}
            />
            <Route
              exact
              path="/warehouse/clients/:id"
              render={(props) => {
                return <WareHouseClients id={props.match.params?.id} key={Date.now()} />;
              }}
            />
            <Route
              exact
              path="/warehouse/clients/products/:warehouse_id/:client_id"
              render={(props) => {
                return <Products warehouse_id={props.match.params?.warehouse_id} client_id={props.match.params?.client_id} key={Date.now()} />;
              }}
            />

            <Route
              path="/users/edit/:id"
              render={(props) => {
                return <EditUsers id={props.match.params.id} />;
              }}
            />
            <Route
              path="/shipping-request/tracking/:id"
              render={(props) => {
                return <Tracking id={props.match.params?.id} />;
              }}
            />
            <Route
              path="/shipping-request/edit/:id"
              render={(props) => {
                return <EditShippingRequest id={props.match.params?.id} />;
              }}
            />
            <Route
              path="/edit-sisl-entity/:id"
              render={(props) => {
                return <AddSislEntity id={props.match.params.id} />;
              }}
            />
            <Route
              path="/edit-customer-entity/:id"
              render={(props) => {
                return <AddCustomerEntity id={props.match.params.id} />;
              }}
            />
            <Route path="/edit-customer-user">
              <EditCustomerUser />
            </Route>
            <Route
              exact
              path="/edit-customer-account/:id?"
              render={(props) => {
                return <EditCustomerAccount id={props.match.params?.id} />;
              }}
            />
            <Route
              exact
              path="/process-shipping/view/:id?"
              render={(props) => {
                return <EditProcessShippingInstruction id={props.match.params?.id} />;
              }}
            />
            <Route exact path="/search">
              <Search_SKU />
            </Route>
          </>
        )}
        {
          sessionData.role_id == 2 && (
            <>
              <Route
                exact
                path="/warehouse/clients/:id"
                render={(props) => {
                  return <WareHouseClients id={props.match.params?.id} key={Date.now()} />;
                }}
              />
              <Route
                exact
                path="/warehouse/clients/products/:warehouse_id/:client_id"
                render={(props) => {
                  return <Products warehouse_id={props.match.params?.warehouse_id} client_id={props.match.params?.client_id} key={Date.now()} />;
                }}
              />
              <Route
                path="/shipping-request/edit/:id"
                render={(props) => {
                  return <EditShippingRequest id={props.match.params?.id} />;
                }}
              />
              <Route
                exact
                path="/process-shipping/view/:id?"
                render={(props) => {
                  return <EditProcessShippingInstruction id={props.match.params?.id} />;
                }}
              />
              <Route exact path="/search">
                <Search_SKU />
              </Route>
            </>
          )
        }
        {
          sessionData.role_id == 5 && (
            <>
              <Route
                exact
                path="/process-shipping/view/:id?"
                render={(props) => {
                  return <EditProcessShippingInstruction id={props.match.params?.id} />;
                }}
              />
            </>
          )
        }
        {
          sessionData.role_id == 3 && (
            <>
              <Route exact path="/search">
                <Search_SKU />
              </Route>
              <Route exact path="/member">
                <WAREHOUSE_OPERATOR_MEMBER />
              </Route>
            </>
          )
        }
        {/* check session and only accesible to admin */}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routers;
