import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
    Row,
    Col,
    Button,
    Input,
    Select,
    Divider,
    notification,
    Drawer,
    Card,
    Table,
    Tag,
    Space,
    DatePicker,
    Spin,
    Popconfirm,
    Modal,
    Breadcrumb,
} from "antd";
import { MinusCircleOutlined, PlusOutlined, FileDoneOutlined, FileAddOutlined, FileTextOutlined } from "@ant-design/icons";
import Form from "../../Helper/Form";
import { sessionData, token } from "../../Helper/Session";
import { withRouter } from "react-router-dom";

import TextArea from "antd/lib/input/TextArea";
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import moment from "moment";
const { Search } = Input;
class CartOut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cart_out: [],
            product_detail: [],
            category: [],
            products: [],
            warehouse: [],
            id: props.id,
            drawerVisible: false,
            visibleStock: "none",
            items: [],
            skus: [],
            cart_detail: [],
            cart_id: 0,
            confirm_stock: [],
            drawerAddFullfillVisible: false,
            tableLoadingRecived: false,
            confirm_stock_detail: [],
            confirm_stock_detail_id: 0,
            drawerViewFullfillVisible: [],
            draweraddCartOutVisible: false,
            drawerViewCartOutVisible: false,
            stock_detail: [],
            stock_detail_id: 0,
            stock: [],
            customer_user: [],
            customer_account: [],
            ship_to_address: "",
            isCartUpdate: false,
            isLoading: false,
            isViewCarOutLoading: false,
            showPpopconfirm: false,
            openSelectCustomerAccountWareHouse: false,
            selectedCustomerAccount: 0,
            selectedWareHouse: 0,
            selectedSkuId: 0,
            cart_out_confirm: [],
            isCartLoading: false,
            serials: [],
            isFullFilldetailLoading: false,
            isConfirmProductsLoading: false,
            isConfirmProductsSaving: false,
            ConfirmDetail: [],
            drawerData_productName: "",
            drawerData_productQty: "",
            available_qty: [],
            selectedRowKeys: "",
            selectedRowKeys2: "",
            openModalConfirmationEmail: false,
            confirm_stock_for_mail: [],
            users_data: [],
            isLoadingCreateGoods: false
        };
    }
    formRef = React.createRef();
    formRefR = React.createRef();
    formRefFR = React.createRef();
    formRefEdit = React.createRef();
    formRefEditStock = React.createRef();
    addCartOut = () => {
        this.setState({ visibleStock: "none", isCartUpdate: false, drawerVisible: true });
    };
    viewProduct = (id) => {
        const dta = {
            product_id: id
        }
        this.setState({ drawerVisible: true, isLoading: true });
        fetch(`${BASE_URL}${API.PRODUCT_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        product_detail: json.data,
                        visibleStock: "block",
                    });
                    this.formRef.current.setFieldsValue({
                        product_name: this.state.product_detail.product_name,
                        sku: this.state.product_detail.sku,
                        category: this.state.product_detail.category,
                        description: this.state.product_detail.description,
                    })
                }
            });
    }
    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const cart_id = queryParams.get("view_confirm");
        if (cart_id != null) {
            this.openFullfillDrawer(cart_id);
            const dta = {
                cart_id: cart_id
            }
            fetch(`${BASE_URL}${API.VERIF_CART_ID}`, {
                method: 'post',
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(dta),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        this.setState({
                            selectedCustomerAccount: ({
                                id: json.data.customer_account,
                                customer_account_name: json.data.customer_account_name
                            }),
                            selectedWareHouse: ({
                                location_name: json.data.warehouse,
                                id: json.data.warehouse_location
                            })
                        });
                    }
                });

        }
        fetch(`${BASE_URL}${API.WAREHOUSE}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        warehouse: json.data,
                    });
                }
            });
        // this.handleCartList();
        // this.fetchCustomerAccount();
        var selectedWareCA = localStorage.getItem('selectedWareCA');
        selectedWareCA = JSON.parse(selectedWareCA);
        if (selectedWareCA != null) {
            if (!selectedWareCA.selectedWareHouse && !selectedWareCA.selectedCustomerAccount) {
                notification.error({ message: 'Error', description: 'Customer account and Warehouse location is not selected! Please select to proceed.', placement: "top" });
            } else {
                this.setState({
                    selectedCustomerAccount: selectedWareCA.selectedCustomerAccount,
                    selectedWareHouse: selectedWareCA.selectedWareHouse
                }, () => {
                    this.handleCartList();
                    this.handleCartListConfirm();
                    this.handleChangeSKU();
                })
            }
        } else {
            notification.error({ message: 'Error', description: 'Customer account and Warehouse location is not selected! Please select to proceed.', placement: "top" });
        }
    }
    fetchUserDetail = (user_id) => {
        const dta = {
            user_id: user_id
        }
        this.setState({
            isViewCarOutLoading: true
        })
        fetch(`${BASE_URL}${API.USER_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        users: json.data,
                    });
                    if (this.state.isCartUpdate == false) {
                        this.formRef.current.setFieldsValue({
                            ship_to_address: (this.state.ship_to_address) ? this.state.ship_to_address.address : '',
                            phone: this.state.users.mobile,
                            email: this.state.users.email,
                        });
                    } else {
                        this.formRefEdit.current.setFieldsValue({
                            ship_to_address: (this.state.ship_to_address) ? this.state.ship_to_address.address : '',
                            phone: this.state.users.mobile,
                            email: this.state.users.email,
                        });
                    }
                }
                this.setState({
                    isViewCarOutLoading: false
                })
            });
    }
    handleSelectCustomerAccount = (value) => {
        let warehouseArray = this.state.warehouse.filter(function (el) {
            return el.id == value.warehouse;
        });
        let CustomerAccountArray = this.state.customer_account.filter(function (ca) {
            return ca.id == value.customer_account;
        });
        this.setState({
            selectedWareHouse: warehouseArray[0],
            selectedCustomerAccount: CustomerAccountArray[0],
            openSelectCustomerAccountWareHouse: false,
            drawerVisible: true,
        })
        this.fetchCustomerUser(value.customer_account);
    }
    fetchCustomerAccount = () => {
        this.setState({
            isViewCarOutLoading: true
        })
        fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        customer_account: json.data,
                    });
                }
                this.setState({
                    isViewCarOutLoading: false
                })
            });
    }
    fetchCustomerUser = (customer_account_id) => {
        const dta = {
            customer_account_id: customer_account_id
        }
        this.setState({
            isViewCarOutLoading: true
        })
        fetch(`${BASE_URL}${API.CUSTOMER_USER_FOR_CUSTOMER_ACCOUNT}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        customer_user: json.data.customer_user,
                        ship_to_address: json.data.address,
                        isViewCarOutLoading: false
                    });
                    if (this.state.isCartUpdate == false) {
                        this.formRef.current.setFieldsValue({
                            ship_to_address: (this.state.ship_to_address) ? this.state.ship_to_address.address : '',
                        });
                    } else {
                        this.formRefEdit.current.setFieldsValue({
                            ship_to_address: (this.state.ship_to_address) ? this.state.ship_to_address : '',
                        });
                    }
                } else {
                    this.setState({
                        customer_user: [],
                        isViewCarOutLoading: false
                    });
                }
            });
    }
    handleCartList = (value) => {
        value = (value) ? value : "";
        this.setState({
            isLoading: true
        });
        fetch(`${BASE_URL}${API.CART_LIST}?cart_type=2&warehouse=` + this.state.selectedWareHouse.id + `&customer_account_id=` + this.state.selectedCustomerAccount.id + `&keyword=` + value, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                // if (json.status === true) {
                this.setState({
                    cart_out: json.data,
                    users_data: json.users,
                });
                // }
                this.setState({
                    isLoading: false,
                });
            });
    }
    handleCartListConfirm = () => {
        this.setState({
            isCartLoading: true
        })
        fetch(`${BASE_URL}${API.CONFIRM_INSTRUCTION}?cart_type=cart_out&warehouse=` + this.state.selectedWareHouse.id + `&customer_account=` + this.state.selectedCustomerAccount.id, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    cart_out_confirm: json.data,
                    isCartLoading: false
                });
            });
    }
    handleChangeSKU = () => {
        const dta = {
            warehouse_id: this.state.selectedWareHouse.id,
            customer_account_id: this.state.selectedCustomerAccount.id
        }
        fetch(`${BASE_URL}${API.GET_SKU}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    const skus = [];
                    json.data.forEach((r) => {
                        skus.push({
                            id: r['sku'],
                            product_name: r['product_name'] + "-" + r['sku'],
                        });
                    });
                    this.setState({ skus });
                }
            });

    }
    handleChangePro = (key, value) => {
        const dta = {
            sku: value,
            warehouse_id: this.state.selectedWareHouse.id,
            customer_account_id: this.state.selectedCustomerAccount.id
        }
        if (value) {
            fetch(`${BASE_URL}${API.AVAILABLE_SKU_SERIAL}`, {
                method: 'post',
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(dta)
            })
                .then((res) => res.json())
                .then((json) => {
                    var itm = {
                        available_qty: ((json.data.qty) && (json.data.qty > 0)) ? json.data.qty : 0
                    };
                    const fields = this.formRef.current.getFieldsValue();
                    const { items } = fields;
                    Object.assign(items[key], itm);
                    this.formRef.current.setFieldsValue({
                        items,
                    });
                    // var available_qty =  json.data.qty;
                    const serials = [];
                    json.data.serial.forEach((r) => {
                        serials.push({
                            id: r
                        });
                    });
                    this.setState({ serials });
                });
        }
    }
    openFullfillDrawer = (id) => {
        this.setState({
            drawerFullfillVisible: true,
            isFullFilldetailLoading: true
        });
        this.handleCartDetail(id);
    }
    openViewCartOutDrawer = (id) => {
        this.setState({
            drawerViewCartOutVisible: true,
            isCartUpdate: true,
            isViewCarOutLoading: true
        });
        this.handleCartViewDetail(id);
    }
    showConfirmedDetail = (id) => {
        var ConfirmDetail = this.state.cart_out_confirm.filter(function (el) {
            return el.id == id;
        });
        // console.log(ConfirmDetail);
        this.setState({
            ConfirmDetail: ConfirmDetail[0],
            ConfirmDetailDrawer: true,
        })
        // console.log(this.state.ConfirmDetail);
    }
    handleCartViewDetail = (id) => {
        // CartOut detail
        const dta = {
            'cart_id': id,
            'cart_type': 'cart_out'
        }
        fetch(`${BASE_URL}${API.CART_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        cart_detail: json.data,
                        cart_id: json.data.id,
                        stock: json.data.stock,
                        confirm_stock: [],
                        customer_user: [{
                            'user_id': json.data.customer_id,
                            'first_name': json.data.first_name,
                            'last_name': json.data.last_name
                        }]
                    });
                    this.formRefEdit.current.setFieldsValue({
                        order_date: this.state.cart_detail.order_date ? moment(this.state.cart_detail.order_date) : '',
                        receiver_name: this.state.cart_detail.receiver_name,
                        phone: this.state.cart_detail.phone,
                        email: this.state.cart_detail.email,
                        remarks: this.state.cart_detail.remarks,
                        ship_to_address: this.state.cart_detail.ship_to_address,
                        order_ref_no: this.state.cart_detail.order_ref_no
                    })
                }
                this.setState({
                    isViewCarOutLoading: false
                })
            });
    }

    handleCartDetail = (id) => {
        // CartOutFullfill detail
        const dta = {
            'cart_id': id,
            'cart_type': 'cart_out'
        }
        this.setState({
            isLoading: true,
            isFullFilldetailLoading: true
        })
        fetch(`${BASE_URL}${API.CART_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        cart_detail: json.data,
                        cart_id: json.data.id,
                        stock: [],
                        confirm_stock: json.data.confirm_stock
                    });
                }
                this.setState({
                    isLoading: false,
                    isFullFilldetailLoading: false
                })
            });
    }

    handleStockDetailDelete = (id, module) => {
        const dta = {
            id: id,
            module: module
        }
        this.setState({
            isViewCarOutLoading: true
        })
        fetch(`${BASE_URL}${API.DELETE_PROPERTY}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    notification.success({ message: json.success_msg });
                    this.handleCartViewDetail(this.state.cart_id);
                }
                this.setState({
                    isViewCarOutLoading: false
                })
            });
    }
    handleStockDetailEdit = (id) => {
        const dta = {
            'stock_id': id
        }
        this.setState({
            draweraddCartOutVisible: true,
            isViewCarOutLoading: true
        })
        fetch(`${BASE_URL}${API.STOCK_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        stock_detail: json.data,
                        stock_detail_id: json.data.id
                    });
                    this.formRefEditStock.current.setFieldsValue({
                        qty: this.state.stock_detail.qty,
                        sku: this.state.stock_detail.sku,
                        serial: (this.state.stock_detail.serial) ? this.state.stock_detail.serial.split(",") : []
                    })
                    // const skus = [];
                    // skus.push({
                    //     id: this.state.stock_detail.sku,
                    //     product_name: this.state.stock_detail.product_name + "-" + this.state.confirm_stock_detail.sku,
                    // });
                    // this.setState({ skus });
                }
                this.setState({
                    isViewCarOutLoading: false
                })
            });
    }
    handleConfirmStockDetail = (id, sku, product_name, qty) => {
        const dta = {
            'stock_id': id,
            'sku': sku,
            'warehouse_id': this.state.selectedWareHouse.id,
            'customer_account_id': this.state.selectedCustomerAccount.id
        }
        this.setState({
            drawerAddFullfillVisible: true,
            stock_id: id,
            selectedSkuId: sku,
            isConfirmProductsLoading: true,
            confirm_stock_detail: [],
            confirm_stock_detail_id: 0,
            drawerData_productName: product_name,
            drawerData_productQty: qty,
            serials: [],
            skus: []
        })
        fetch(`${BASE_URL}${API.CONFIRM_STOCK_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    if (json.data[0]) {
                        this.setState({
                            confirm_stock_detail: json.data[0],
                            confirm_stock_detail_id: json.data[0].id
                        });

                        this.formRefFR.current.setFieldsValue({
                            qty: this.state.confirm_stock_detail.qty,
                            sku: this.state.confirm_stock_detail.sku,
                            serial: (this.state.confirm_stock_detail.serial) ? this.state.confirm_stock_detail.serial.split(",") : [],
                            confirm_date: this.state.confirm_stock_detail.confirm_date ? moment(this.state.confirm_stock_detail.confirm_date) : '',
                            carrier: this.state.confirm_stock_detail.carrier,
                            tracking: this.state.confirm_stock_detail.tracking,
                            shippro_reference: this.state.confirm_stock_detail.shippro_reference
                        })
                        const skus = [];
                        skus.push({
                            id: this.state.confirm_stock_detail.sku,
                            product_name: this.state.confirm_stock_detail.product_name + "-" + this.state.confirm_stock_detail.sku,
                        });
                        this.setState({ skus });
                    }
                    const serials = [];
                    json.data.available_serial.serial.forEach((r) => {
                        serials.push({
                            id: r
                        });
                    });
                    this.setState({ serials });
                }
                this.setState({
                    isConfirmProductsLoading: false
                })
            });
    }
    openModalConfirmationEmailUp = () => {
        fetch(`${BASE_URL}${API.CONFIRM_STOCK_FOR_MAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ cart_id: this.state.cart_id })
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        confirm_stock_for_mail: json.data,
                        openModalConfirmationEmail: true
                    })
                } else {
                    this.setState({
                        confirm_stock_for_mail: []
                    })
                    notification.error({ message: json.error_msg })
                }
            })
    }
    onSelectChange = (newSelectedRowKeys) => {
        this.setState({
            selectedRowKeys: newSelectedRowKeys
        })
    };
    onSelectChange2 = (newSelectedRowKeys2) => {
        this.setState({
            selectedRowKeys2: newSelectedRowKeys2
        })
    };
    send_email = () => {
        fetch(`${BASE_URL}${API.SEND_CONFIRMATION_EMAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ cart_id: this.state.cart_id, confirmation_id: this.state.selectedRowKeys })
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({ openModalConfirmationEmail: false });
                    notification.success({ message: json.success_msg })
                } else {
                    notification.error({ message: json.error_msg })
                }
            })
    }
    searchKeyword = (v) => {
        this.handleCartList(v);
    }
    render() {
        const { Option } = Select;
        const { users_data, confirm_stock_for_mail, openModalConfirmationEmail, selectedRowKeys, selectedRowKeys2, warehouse, cart_out, cart_detail, skus, confirm_stock, selectedSkuId, tableLoadingRecived, isFullFilldetailLoading, drawerViewCartOutVisible, showPpopconfirm, isViewCarOutLoading, stock, customer_account, isLoadingCreateGoods, isLoading, openSelectCustomerAccountWareHouse, selectedCustomerAccount, selectedWareHouse, cart_out_confirm, isCartLoading, serials, isConfirmProductsLoading, isConfirmProductsSaving, ConfirmDetail, drawerData_productQty, drawerData_productName, available_qty } = this.state;
        const skus_options = skus.map(d => <Option key={d.id} value={d.id}>{d.product_name}</Option>);
        const serial_options = serials.map(d => <Option key={d.id} value={d.id}>{d.id}</Option>);
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const rowSelection2 = {
            selectedRowKeys2,
            onChange: this.onSelectChange,
        };
        var data = [];
        var data_Fullfill_pro = [];
        var data_stock_pro = [];
        var data_confirm_instruction = [];
        var data_confirm_stock_for_mail = [];
        var serial_options_data = [];
        const serial_options_col = [
            {
                title: "Serial",
                dataIndex: "serial"
            }
        ]
        const confirm_instruction_mail = [
            {
                title: "Goods-In Confirmation ID",
                dataIndex: "id"
            },
            // {
            //     title: "Goods-In Instruction ID",
            //     dataIndex: "instruction_id"
            // },
            {
                title: "Product",
                dataIndex: "product"
            },
            {
                title: "Quantity Received",
                dataIndex: "qty"
            },
            {
                title: "Confirmed By",
                dataIndex: "created_by"
            },

        ]
        const confirm_instruction = [
            {
                title: "Goods-Out Confirmation Id",
                dataIndex: "id"
            },
            {
                title: "Goods-Out Instruction Id",
                dataIndex: "instruction_id"
            },
            {
                title: "Product",
                dataIndex: "product"
            },
            {
                title: "Quantity Delivered",
                dataIndex: "qty"
            },
            {
                title: "Confirmed by",
                dataIndex: "created_by"
            },

        ]
        const columns = [
            {
                title: "Goods-Out Instruction Id",
                dataIndex: "id",
            },
            {
                title: "Order Reference no",
                dataIndex: "order_ref_no",
            },
            {
                title: "Customer name",
                dataIndex: "customer_name",
            },
            {
                title: "Created by",
                dataIndex: "created_by",
            },
            {
                title: "Instruction date",
                dataIndex: "order_date",
            },
            {
                title: "Status",
                dataIndex: "status",
                filters: [
                    {
                        text: 'In process',
                        value: 'In process',
                    },
                    {
                        text: 'Delivered',
                        value: 'Delivered',
                    },
                    {
                        text: 'Not delivered',
                        value: 'Not delivered',
                    },
                ],
                filterSearch: true,
                onFilter: (value, record) => record.status.props.children.props.children.includes(value),
                width: '30%',
            },
            {
                title: "Confirm Goods-Out Instruction",
                dataIndex: "action",
            }
        ];
        if (sessionData.role_id == 4 || sessionData.role_id == 6) {
            columns.splice(6, 1)
        }
        const columns_Fullfill_products = [
            {
                title: "SNo",
                dataIndex: "key",
            },
            {
                title: "Product name",
                dataIndex: "product_name",
            },
            {
                title: "Instruction Quantity",
                dataIndex: "qty",
                render: (qty) => <Tag color="purple">{qty}</Tag>,
            },
            {
                title: "Delivered Quantity",
                dataIndex: "rec_qty",
                render: (rec_qty) => <Tag color="purple">{rec_qty}</Tag>,
            },
            {
                title: "Serial no.",
                dataIndex: "serial",
                render: (_, { serial }) => (
                    <>
                        {serial.map((serial) => {
                            let color = serial.length > 5 ? 'geekblue' : 'green';
                            return (
                                <Tag color={color} key={serial}>
                                    {serial.toUpperCase()}
                                </Tag>
                            );
                        })}
                    </>
                ),
            },
            {
                title: "Fullfill date",
                dataIndex: "confirm_date",
            },
            {
                title: "Edit",
                dataIndex: "action",
            }
        ];
        if (sessionData.role_id == 4 || sessionData.role_id == 6 || sessionData.role_id == 2) {
            columns_Fullfill_products.splice(6, 1)
        }
        const stock_pro = [
            {
                title: "SNo",
                dataIndex: "id",
            },
            {
                title: "Product name",
                dataIndex: "product_name",
            },
            {
                title: "Quantity",
                dataIndex: "qty",
                render: (qty) => <Tag color="purple">{qty}</Tag>,
            },
            {
                title: "Serial no.",
                dataIndex: "serial",
                render: (_, { serial }) => (
                    <>
                        {serial.map((serial) => {
                            let color = serial.length > 5 ? 'geekblue' : 'green';
                            return (
                                <Tag color={color} key={serial}>
                                    {serial.toUpperCase()}
                                </Tag>
                            );
                        })}
                    </>
                ),
            },
            {
                title: "Edit",
                dataIndex: "action",
            }
        ];
        serials.map((srl, i) =>
            serial_options_data.push({
                serial: srl.id,
                key: srl.id,
            })
        );
        stock.map((drp, i) =>
            data_stock_pro.push({
                id: i + 1,
                product_name: drp['product_name'] + "-" + drp['sku'],
                warehouse: drp['warehouse'],
                qty: drp['qty'],
                serial: (drp['serial']) ? drp['serial'].split(",") : [],
                action: (
                    <>
                        {sessionData.role_id != 6 && (
                            <Button
                                type="primary"
                                size="small"
                                ghost
                                icon={<EditOutlined />}
                                style={{ fontSize: "12px" }}
                                onClick={() => this.handleStockDetailEdit(drp['id'])}
                            >
                            </Button>)}
                        {sessionData.role_id != 6 && (
                            <Popconfirm
                                title="Are you sure, You want to delete this product?"
                                open={showPpopconfirm}
                                onConfirm={() => this.handleStockDetailDelete(drp['id'], "stock")}
                                onCancel={() => this.setState({ showPpopconfirm: false })}
                            >
                                <Button
                                    type="danger"
                                    size="small"
                                    ghost
                                    icon={<DeleteOutlined />}
                                    style={{ fontSize: "12px" }}
                                    onClick={() => this.setState({ showPpopconfirm: true })}
                                >
                                </Button>
                            </Popconfirm>
                        )}
                    </>
                )
            })
        );
        confirm_stock_for_mail.map((pro) => {
            data_confirm_stock_for_mail.push({
                key: pro.id,
                id: pro['instruction_id'] + "" + pro['id'],
                created_by: pro.created_by,
                qty: pro.qty,
                product: pro.product_name
            })
        })
        confirm_stock.map((drp, i) =>
            data_Fullfill_pro.push({
                key: i + 1,
                product_name: drp['product_name'] + "-" + drp['sku'],
                qty: drp['qty'],
                rec_qty: drp['received']['qty'],
                serial: (drp['received']['serial']) ? drp['received']['serial'].split(",") : [],
                confirm_date: (drp['received']['confirm_date']) ? moment(drp['received']['confirm_date']).format("DD-MM-YYYY") : '-',
                action: (
                    <>
                        {(sessionData.role_id != 2 && sessionData.role_id != 4 && sessionData.role_id != 6) && (
                            <Button
                                type="primary"
                                size="small"
                                ghost
                                icon={<EditOutlined />}
                                style={{ fontSize: "12px" }}
                                onClick={() => this.handleConfirmStockDetail(drp['stock_id'], drp['sku'], drp.product_name, drp.qty)}
                            >
                            </Button>
                        )}
                        {/* <Popconfirm
                            title="Are you sure, You want to delete this product?"
                            open={showPpopconfirm}
                            onConfirm={() => this.handleStockDetailDelete(drp['id'], "confirm_stock")}
                            onCancel={() => this.setState({ showPpopconfirm: false })}
                        >
                            <Button
                                type="danger"
                                size="small"
                                ghost
                                icon={<DeleteOutlined />}
                                style={{ fontSize: "12px" }}
                                onClick={() => this.setState({ showPpopconfirm: true })}
                            >
                            </Button>
                        </Popconfirm> */}
                    </>
                )
            })
        );
        cart_out_confirm.map((pro) => {
            data_confirm_instruction.push({
                id: (<Button type="link" onClick={() => this.showConfirmedDetail(pro.id)}>{pro['instruction_id'] + "" + pro['id']}</Button>),
                instruction_id: pro.instruction_id,
                created_by: pro.created_by,
                qty: pro.qty,
                product: pro.product_name
            })
        })
        cart_out.map((pro, i) =>
            data.push({
                id: (<a
                    style={{ fontSize: "12px" }}
                    onClick={() => (sessionData.role_id != 6) ? this.openViewCartOutDrawer(pro["id"]) : this.openFullfillDrawer(pro["id"])}
                >{pro.instruction_id}
                </a>),
                order_ref_no: pro.order_ref_no,
                customer_name: pro.customer_name,
                created_by: pro['created_by'],
                order_date: (pro['order_date']) ? moment(pro['order_date']).format("DD-MM-YYYY") : '',
                status: (
                    <>
                        <Tag color={(pro['confirm_stock_qty'] == pro['stock_qty']) ? 'green' : (pro['confirm_stock_qty'] > 0) ? 'orange' : ''}>{(pro['confirm_stock_qty'] == pro['stock_qty']) ? 'Delivered' : (pro['confirm_stock_qty'] > 0) ? 'In process' : 'Not delivered'}</Tag>
                    </>
                ),
                action: (
                    <>
                        {(sessionData.role_id == 1 || sessionData.role_id == 3) && (
                            <Button
                                type="secondary"
                                onClick={() => this.openFullfillDrawer(pro["id"])}
                                size="small"
                                icon={<FileDoneOutlined />}
                                style={{ fontSize: "12px" }}
                                title="Goods-Out Confirmation"
                            > Confirm
                            </Button>
                        )}
                        {(sessionData.role_id == 2) && (
                            <Button
                                type="secondary"
                                onClick={() => this.openFullfillDrawer(pro["id"])}
                                size="small"
                                icon={<FileDoneOutlined />}
                                style={{ fontSize: "12px" }}
                                title="View Confirm Information"
                            > View Confirm Informatiom
                            </Button>
                        )}
                        {/* {(pro['shipment_status'] != 0) && (<Button
                            type="secondary"
                            onClick={() => this.props.history.push("/shipping-request/view/" + pro['id'])}
                            size="small"
                            icon={<FileTextOutlined />}
                            style={{ fontSize: "12px" }}
                            title="Shipping Info"
                        > Shipping Info
                        </Button>)} */}
                    </>
                ),
            })
        );
        const onFinish = (values) => {
            this.setState({
                isCartUpdate: false,
                isViewCarOutLoading: true,
                isLoadingCreateGoods: true
            })
            values.order_date = moment(values.order_date).format("YYYY-MM-DD");
            values.customer_account = selectedCustomerAccount.id;
            values.warehouse_location = selectedWareHouse.id;
            fetch(`${BASE_URL}${API.ADD_CARTOUT}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(values),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: json.success_msg });
                        this.handleCartList();
                        this.setState({ drawerVisible: false });
                    } else {
                        notification.error({ message: json.error_msg });
                    }
                    this.setState({
                        isViewCarOutLoading: false,
                        isLoadingCreateGoods: false
                    })
                });
        };
        const handleCartOutUpdate = (values) => {
            this.setState({
                isViewCarOutLoading: true
            })
            values.order_date = moment(values.order_date).format("YYYY-MM-DD");
            values.cart_id = this.state.cart_id;
            values.cart_type = 'cart_out';
            values.customer_account = selectedCustomerAccount.id;
            values.warehouse_location = selectedWareHouse.id;
            fetch(`${BASE_URL}${API.UPDATE_CARTOUT}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(values),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: json.success_msg });
                        this.handleCartList();
                        this.setState({ drawerViewCartOutVisible: false });
                    } else {
                        notification.error({ message: json.error_msg });
                    }
                    this.setState({
                        isViewCarOutLoading: false
                    })
                });
        }
        const onFinishFullfill = (values) => {
            values.cart_id = this.state.cart_id;
            values.stock_id = this.state.stock_id;
            values.cart_type = 'cart_out';
            values.sku = this.state.selectedSkuId;
            values.confirm_date = moment(values.confirm_date).format("YYYY-MM-DD");
            if (this.state.confirm_stock_detail_id != 0) {
                values.confirm_stock_id = this.state.confirm_stock_detail_id;
            }
            values.customer_account = selectedCustomerAccount.id;
            values.warehouse_location = selectedWareHouse.id;
            this.setState({
                isConfirmProductsSaving: true
            })
            fetch(`${BASE_URL}${API.ADD_CONFIRM_STOCK}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(values),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: 'Success', description: json.success_msg });
                        this.setState({ drawerAddFullfillVisible: false, serials: [] });
                        //load mainRecieve drawer
                        this.handleCartDetail(this.state.cart_id);
                        this.handleCartList();
                    } else {
                        notification.error({ message: 'Error', description: json.error_msg });
                    }
                    this.setState({
                        isConfirmProductsSaving: false
                    })
                });
        };

        const onFinishaddCartOutProduct = (values) => {
            values.cart_id = this.state.cart_id;
            values.cart_type = 'cart_out';
            values.is_update = "yes_update";
            this.setState({
                isViewCarOutLoading: true
            });
            if (this.state.stock_detail_id != 0) {
                values.stock_id = this.state.stock_detail_id;
            }
            fetch(`${BASE_URL}${API.SAVE_CART_STOCK}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(values),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: 'Success', description: json.success_msg });
                        this.setState({ draweraddCartOutVisible: false });
                        //load mainRecieve drawer
                        this.handleCartViewDetail(this.state.cart_id);
                    } else {
                        notification.error({ message: 'Error', description: json.error_msg });
                    }
                    this.setState({
                        isViewCarOutLoading: false
                    })
                });
        }
        return (
            <Headers
                bodyContain={
                    <>
                        <Row>
                            <Col md={16}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{selectedWareHouse.location_name}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{selectedCustomerAccount.customer_account_name}</Breadcrumb.Item>
                                    <Breadcrumb.Item>Goods-Out Instruction</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                            {(sessionData.role_id != 6) && (
                                <Col>
                                    <Button
                                        onClick={this.addCartOut}
                                        type="secondary"
                                        style={{ marginBottom: "10px" }}
                                        icon={<FileAddOutlined />}
                                        size="medium"

                                    >
                                        Create a new Goods-Out Instructions
                                    </Button>
                                </Col>
                            )}
                        </Row>
                        <div className="site-layout-background form-design">

                            <Spin spinning={isLoading}>
                                <Row>
                                    <Col md={18}>
                                        <h1>Goods-Out Instructions List</h1>
                                    </Col>
                                    <Col md={6} style={{ "marginBottom": "10px", "marginTop": "-5px" }}>

                                        <Search className="search" onSearch={this.searchKeyword} placeholder="Search" style={{ "width": "100%" }} />

                                    </Col>
                                    <Col md={24}>
                                        <Table
                                            columns={columns}
                                            dataSource={data}
                                            size="small"
                                            bordered
                                            loading={isLoading}
                                            pageSize="10"
                                        />
                                    </Col>
                                </Row>
                                <Divider />

                                <Row>
                                    <Col md={15}><h1>Goods-Out Confirmation List</h1></Col>
                                    <Col><Button icon={<DownloadOutlined />} onClick={() => window.location.href = BASE_URL + "" + API.GENERATE_REPORT + "?customer_account=" + this.state.selectedCustomerAccount.id + "&warehouse=" + this.state.selectedWareHouse.id + "&cart_type=cart_out"} type="primary" size="small" style={{ marginBottom: "10px" }}>Generate Goods-Out Confirmation Report</Button></Col>
                                </Row>
                                <Row>
                                    <Col md={24}>
                                        <Table
                                            columns={confirm_instruction}
                                            dataSource={data_confirm_instruction}
                                            size="small"
                                            bordered
                                            loading={isCartLoading}
                                        />
                                    </Col>
                                </Row>

                            </Spin>
                            <Divider />
                            <Drawer title="Create Goods-Out Instruction" placement="right" closable={true}
                                mask={true}
                                width={900}
                                visible={this.state.drawerVisible}
                                onClose={() => { this.setState({ drawerVisible: false }) }}
                                destroyOnClose>
                                <Card>
                                    <Form
                                        name="register"
                                        layout="vertical"
                                        onFinish={onFinish}
                                        scrollToFirstError
                                        ref={this.formRef}
                                        className="add_cart_out"
                                    >
                                        <Row>
                                            <Col md={12}>Customer Account -  <Tag color="purple">{selectedCustomerAccount.customer_account_name}</Tag></Col>
                                            <Col md={12}>WareHouse -  <Tag color="green">{selectedWareHouse.location_name}</Tag></Col>
                                        </Row>
                                        <Divider />
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="order_date"
                                                    label="Instruction date"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please enter Instruction date!",
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker style={{ width: "100%" }}
                                                        onChange={this.onChange}
                                                        format="DD-MM-YYYY"
                                                        defaultValue={moment()} />
                                                </Form.Item>
                                            </Col>
                                            {/* <Col sm={6}>
                                                <Form.Item
                                                    name="customer_account"
                                                    label="Customer Account"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please select customer account!",
                                                        },
                                                    ]}

                                                >
                                                    <Select placeholder="Select customer account" onChange={this.fetchCustomerUser}>
                                                        <Option value="">Select customer account</Option>
                                                        {customer_account.map((opt, i) => {
                                                            return <Option value={opt.id}>{opt.customer_account_name}</Option>;
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col> */}
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="receiver_name"
                                                    label="Receiver's name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter Receiver's name!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={6}>
                                                <Form.Item
                                                    name="phone"
                                                    label="Receiver's phone"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter customer phone!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="customer_name"
                                                    label="Customer name"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please enter customer name!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="email"
                                                    label="Receiver's email"
                                                    rules={[
                                                        {
                                                            type: "email",
                                                            required: true,
                                                            message: "Please enter correct customer email!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={12}>
                                                <Form.Item
                                                    name="ship_to_address"
                                                    label="Receiver's address"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please add ship to address.",
                                                        },
                                                    ]}
                                                >
                                                    <TextArea />
                                                </Form.Item>
                                            </Col>
                                            {/* Requested by Ankur sir on 09-OCT-23 */}
                                            <Col sm={12}>
                                                <Form.Item
                                                    name="order_ref_no"
                                                    label="Order reference no"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please enter order reference number!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            {/* Requested by Ankur sir on 09-OCT-23 */}
                                            <Divider />
                                            <Form.List name="items">
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }) => (
                                                            <Space key={key}>
                                                                <Row>
                                                                    {/* <Divider orientation="left" plain>
                                                                        Cart {key + 1}
                                                                    </Divider> */}
                                                                    <Col>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, "sku"]}
                                                                            label="Product and SKU"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please enter product & SKU!",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                showSearch
                                                                                style={{ width: 200 }}
                                                                                // onSearch={this.handleChangeSKU}
                                                                                onChange={this.handleChangePro.bind(this, key)}
                                                                                size="small"
                                                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                            >
                                                                                {skus_options}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col sm={4}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, "available_qty"]}
                                                                            label="Quantity in Stock"
                                                                        // rules={[
                                                                        //     {
                                                                        //         // required: true,
                                                                        //         // message: "Please enter quantity!",
                                                                        //     },
                                                                        // ]}
                                                                        >
                                                                            <Input
                                                                                size="small" disabled
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, "qty"]}
                                                                            label="Quantity"
                                                                            dependencies={[name, "available_qty"]}
                                                                            hasFeedback
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please enter quantity!",
                                                                                },
                                                                                ({ getFieldValue }) => ({
                                                                                    validator(_, value) {
                                                                                        // console.log(getFieldValue("items")[key]['available_qty']);
                                                                                        if (!value || value <= getFieldValue("items")[key]['available_qty']) {
                                                                                            return Promise.resolve();
                                                                                        }
                                                                                        return Promise.reject(
                                                                                            new Error(
                                                                                                "Quantity can not exeeds from available Quantity!"
                                                                                            )
                                                                                        );
                                                                                    },
                                                                                }),
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                placeholder="Quantity"
                                                                                size="small"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, "confirm_date"]}
                                                                            label="Requested delivery date"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please requested delivery date!",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <DatePicker type="date" size="small" />
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, "serial"]}
                                                                            label="Serial numbers"
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                    message: "Please enter serial numbers!",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            {/* <Input
                                                                                placeholder="Serial numbers"
                                                                                size="small"
                                                                            /> */}
                                                                            <Select
                                                                                size="small"
                                                                                mode="tags"
                                                                                style={{ width: "300px" }}
                                                                                tokenSeparators={[',']}
                                                                            >
                                                                                {serial_options}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Button type="link" style={{ top: "10px" }} danger icon={<MinusCircleOutlined />} onClick={() => remove(name)} />
                                                                {/* <PlusCircleOutlined onClick={() => add(name)} /> */}
                                                            </Space>
                                                        ))}
                                                        <Col md={24}>
                                                            <Form.Item>
                                                                <Button
                                                                    type="dashed-secondary"
                                                                    size="small"
                                                                    onClick={() => add()}
                                                                    block
                                                                    style={{ width: "100%" }}
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    Add Products
                                                                </Button>
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )}
                                            </Form.List>
                                            <Divider />
                                            <Col sm={24}>
                                                <Form.Item
                                                    name="remarks"
                                                    label="Remarks"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please enter remarks!",
                                                        },
                                                    ]}
                                                >
                                                    <TextArea />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={24}>
                                                {/* new field addded */}
                                                <Col sm={12}>
                                                    <Form.Item
                                                        name="assign_to"
                                                        label="Assign to"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select a user to assign this task!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Select user" showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {users_data.map((opt, i) => {
                                                                return <Option value={opt.id}>{opt.first_name + " " + opt.last_name}</Option>;
                                                            })}
                                                        </Select>
                                                    </Form.Item>

                                                </Col>
                                                {/* new field addded */}
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" loading={isLoadingCreateGoods} size="meduim" icon={<AppstoreAddOutlined />}>
                                                        Create Goods-Out Instruction
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            </Drawer>
                            <Drawer title="Goods-Out Confirmation " placement="right" closable={true}
                                mask={true}
                                width={900}
                                visible={this.state.drawerFullfillVisible}
                                onClose={() => { this.setState({ drawerFullfillVisible: false, drawerAddFullfillVisible: false }) }}
                                destroyOnClose
                                extra={
                                    (sessionData.role_id != 6 && sessionData.role_id != 2) && (
                                        <Space>
                                            <Button type="success" onClick={this.openModalConfirmationEmailUp}>
                                                Send Confirmation Email
                                            </Button>
                                        </Space>
                                    )
                                }
                            >
                                <Spin spinning={isFullFilldetailLoading}>
                                    <Row>
                                        <div className="cart_box cart_out_box">
                                            <div class="summary_box">
                                                <Col md={12}>
                                                    <div class="summary_box">
                                                        <p>Instruction date</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="green">{moment(cart_detail.order_date).format("DD-MM-YYYY")}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div class="summary_box">
                                                        <p>Receiver's Name</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="green">{cart_detail.receiver_name}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="summary_box">
                                                <Col md={12}>
                                                    <div class="summary_box">
                                                        <p>Receiver's Phone</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="green">{cart_detail.phone}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div class="summary_box">
                                                        <p>Receiver's Email</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="green">{cart_detail.email}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="summary_box">
                                                <Col md={12}>
                                                    <div class="summary_box">
                                                        <p>Ship to address</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="green">{cart_detail.ship_to_address}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="summary_box">
                                                <Col md={12}>
                                                    <div class="summary_box">
                                                        <p>Order reference no.</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="green">{cart_detail.order_ref_no}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                        <Divider />
                                        <Col md={24}>
                                            <Table
                                                dataSource={data_Fullfill_pro}
                                                columns={columns_Fullfill_products}
                                                pagination={false}
                                                tableLayout="auto"
                                                size="small"
                                                width="100%"
                                                bordered
                                                loading={tableLoadingRecived}
                                            />
                                        </Col>
                                        <Divider />
                                        {/* <Col md={24}>
                                        <Form.Item>
                                            <Button
                                                type="dashed-secondary"
                                                size="small"
                                                onClick={() => this.setState({ drawerAddFullfillVisible: true })}
                                                block
                                                style={{ width: "100%" }}
                                                icon={<PlusOutlined />}
                                            >
                                                Add Products
                                            </Button>
                                        </Form.Item>
                                    </Col> */}
                                    </Row>
                                </Spin>
                                <Drawer title="Add Confirm Products" placement="right" closable={true}
                                    mask={true}
                                    width={500}
                                    visible={this.state.drawerAddFullfillVisible}
                                    onClose={() => { this.setState({ drawerAddFullfillVisible: false }) }}
                                    destroyOnClose
                                    extra={
                                        <Space>
                                            <Button onClick={() => { this.setState({ drawerAddFullfillVisible: false }) }}>Cancel</Button>
                                        </Space>
                                    }>
                                    <Spin spinning={isConfirmProductsLoading}>
                                        <Row>
                                            <Col span={24}>
                                                Product: <Tag color="gold">{drawerData_productName}</Tag>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "15px" }}>
                                            <Col span={12}>
                                                Instruction Quantity: <Tag color="purple"> {drawerData_productQty}</Tag>
                                            </Col>
                                        </Row>
                                        <Divider />
                                        <Form
                                            name="register"
                                            layout="vertical"
                                            onFinish={onFinishFullfill}
                                            scrollToFirstError
                                            ref={this.formRefFR}
                                            className=""
                                        >
                                            <Row gutter={16}>
                                                {/* <Col span={12}>
                                                <Form.Item
                                                    name="sku"
                                                    label="Product and SKU"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter product & SKU!",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        // style={{ width: 200 }}
                                                        onSearch={this.handleChangeSKU}
                                                        placeholder="Products & SKU"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                        {skus_options}
                                                    </Select>
                                                </Form.Item>
                                            </Col> */}
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="qty"
                                                        label="Quantity"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter Quantity',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Quantity"
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item
                                                        name="serial"
                                                        label="Serial numbers"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: "Please enter serial numbers!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            mode="tags"
                                                            virtual={false}
                                                            tokenSeparators={[',']}
                                                        >
                                                            {serial_options}

                                                        </Select>
                                                    </Form.Item>
                                                    {/* <Table
                                                        rowSelection={rowSelection2}
                                                        columns={serial_options_col}
                                                        dataSource={serial_options_data}
                                                        size="small"
                                                        bordered
                                                    /> */}
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="confirm_date"
                                                        label="Delivery date"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter the delivery date',
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item
                                                        name="carrier"
                                                        label="Carrier"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: 'Please enter carrier name.',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="tracking"
                                                        label="Tracking no."
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: 'Please enter tracking number.',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item
                                                        name="shippro_reference"
                                                        label="Ship-Pro reference"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: 'Please enter Shippro reference!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>

                                                <Col md={24}>
                                                    <Button type="primary" htmlType="submit" size="meduim" style={{ width: "100%" }} icon={<AppstoreAddOutlined />} loading={isConfirmProductsSaving}>
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Spin>
                                </Drawer>
                            </Drawer>
                            {/* Add Cart out Detail Drawer */}
                            <Drawer title="Goods-Out Intstruction Detail" placement="right" closable={true}
                                mask={true}
                                width={800}
                                visible={this.state.drawerViewCartOutVisible}
                                onClose={() => { this.setState({ drawerViewCartOutVisible: false, draweraddCartOutVisible: false }) }}
                                destroyOnClose>
                                <Spin spinning={isViewCarOutLoading}>
                                    <Form
                                        name="register"
                                        layout="vertical"
                                        onFinish={handleCartOutUpdate}
                                        scrollToFirstError
                                        ref={this.formRefEdit}
                                        className="add_cart_out"
                                    >
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="order_date"
                                                    label="Instruction date"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter Instruction date!",
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="receiver_name"
                                                    label="Receiver name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter Receiver name!",
                                                        },
                                                    ]}

                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={6}>
                                                <Form.Item
                                                    name="phone"
                                                    label="Customer phone"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter customer phone!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="customer_name"
                                                    label="Customer Name"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please enter customer name!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={12}>
                                                <Form.Item
                                                    name="email"
                                                    label="Customer email"
                                                    rules={[
                                                        {
                                                            type: "email",
                                                            required: true,
                                                            message: "Please enter correct customer email!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12}>
                                                <Form.Item
                                                    name="ship_to_address"
                                                    label="Ship to address"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please add ship to address.",
                                                        },
                                                    ]}
                                                >
                                                    <TextArea />
                                                </Form.Item>
                                            </Col>
                                            {/* Requested by Ankur sir on 09-OCT-23 */}
                                            <Col sm={12}>
                                                <Form.Item
                                                    name="order_ref_no"
                                                    label="Order reference no"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please enter order reference number!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            {/* Requested by Ankur sir on 09-OCT-23 */}
                                            <Col sm={24}>
                                                <Form.Item
                                                    name="remarks"
                                                    label="Remarks"
                                                    rules={[
                                                        {

                                                            required: false,
                                                            message: "Please enter remarks!",
                                                        },
                                                    ]}
                                                >
                                                    <TextArea />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Divider />
                                        <Row>
                                            <Col md={24}>
                                                <Table
                                                    dataSource={data_stock_pro}
                                                    columns={stock_pro}
                                                    pagination={false}
                                                    tableLayout="auto"
                                                    size="small"
                                                    width="100%"
                                                    bordered
                                                />
                                            </Col>
                                            <Col md={24}>
                                                <Form.Item>
                                                    <Button
                                                        type="dashed-secondary"
                                                        size="small"
                                                        onClick={() => this.setState({ draweraddCartOutVisible: true })}
                                                        block
                                                        style={{ width: "100%" }}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add Product
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Drawer title="Add Products" placement="right" closable={true}
                                            mask={true}
                                            width={500}
                                            visible={this.state.draweraddCartOutVisible}
                                            onClose={() => { this.setState({ draweraddCartOutVisible: false }) }}
                                            destroyOnClose
                                            extra={
                                                <Space>
                                                    <Button onClick={() => { this.setState({ draweraddCartOutVisible: false }) }}>Cancel</Button>
                                                </Space>
                                            }>
                                            <Form
                                                name="register"
                                                layout="vertical"
                                                onFinish={onFinishaddCartOutProduct}
                                                scrollToFirstError
                                                ref={this.formRefEditStock}
                                                className=""
                                            >
                                                <Row gutter={16}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="sku"
                                                            label="Product and SKU"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please enter product & SKU!",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                // style={{ width: 200 }}
                                                                // onSearch={this.handleChangeSKU}
                                                                placeholder="Products & SKU"
                                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                            >
                                                                {skus_options}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="qty"
                                                            label="Quantity"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter Quantity',
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                placeholder="Quantity"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="serial"
                                                            label="Serial numbers"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: "Please enter serial numbers!",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                mode="tags"
                                                                // style={{ width: "300px" }}
                                                                tokenSeparators={[',']}
                                                            >
                                                                {serial_options}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col md={24}>
                                                        <Button type="primary" htmlType="submit" size="meduim" style={{ width: "100%" }} icon={<AppstoreAddOutlined />}>
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Drawer>
                                        <Row>
                                            <Col sm={24}>
                                                <Form.Item>
                                                    <Button type="primary" size="meduim" htmlType="submit" icon={<AppstoreAddOutlined />}>
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Spin>
                            </Drawer>
                            {/* Add cart out detail */}
                            <Modal
                                title="Select Customer Account & WareHouse"
                                style={{ top: 20 }}
                                visible={openSelectCustomerAccountWareHouse}
                                width={600}
                                onCancel={() => this.setState({ openSelectCustomerAccountWareHouse: false })}
                                footer={[
                                    <Button
                                        key="cancel"
                                        type="default"
                                        loading=""
                                        onClick={() => this.setState({ openSelectCustomerAccountWareHouse: false })}
                                    >
                                        Cancel
                                    </Button>,
                                ]}>
                                <Form
                                    name="select_customer_account_warehouse"
                                    layout="vertical"
                                    onFinish={this.handleSelectCustomerAccount}
                                    scrollToFirstError
                                    ref={this.formRef}
                                >
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item
                                                name="customer_account"
                                                label="Customer account"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select customer account name!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    allowClear={true}

                                                >
                                                    {customer_account.map((ca) => {
                                                        return (
                                                            <Option key={ca.id} value={ca.id}>
                                                                {ca.customer_account_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="warehouse"
                                                label="WareHouse"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select warehouse!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    allowClear={true}

                                                >
                                                    {warehouse.map((opt) => {
                                                        return (
                                                            <Option value={opt.id}>{opt.location_name}</Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item>
                                                <Button
                                                    type="info"
                                                    htmlType="submit"
                                                    size="medium"
                                                    loading={false}
                                                >
                                                    Continue
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal>
                            {/* Modal for selecting customer account and warehouse */}
                            {/* modal for confimration email */}
                            <Modal
                                title="Confirmation Email"
                                style={{ top: 20 }}
                                visible={openModalConfirmationEmail}
                                width={900}
                                destroyOnClose
                                onCancel={() => this.setState({ openModalConfirmationEmail: false })}
                                footer={[
                                    <Button
                                        key="cancel"
                                        type="default"
                                        loading=""
                                        onClick={() => this.setState({ openModalConfirmationEmail: false })}
                                    >
                                        Cancel
                                    </Button>,
                                ]}>
                                <Table
                                    rowSelection={rowSelection}
                                    columns={confirm_instruction_mail}
                                    dataSource={data_confirm_stock_for_mail}
                                    size="small"
                                    bordered
                                    loading={isCartLoading}
                                    pagination={false}
                                />
                                <Button
                                    key="send_email"
                                    type="primary"
                                    loading=""
                                    onClick={this.send_email}
                                    style={{
                                        marginTop: "10px",
                                        float: "right",
                                        marginBottom: "20px"
                                    }}
                                >
                                    Send Email
                                </Button>,
                            </Modal>
                            {/* end confirmation email */}
                            <Drawer title="Goods-Out Confirmation Detail" placement="top" closable={true}
                                mask={true}
                                height={"auto"}
                                visible={this.state.ConfirmDetailDrawer}
                                onClose={() => { this.setState({ ConfirmDetailDrawer: false }) }}
                                destroyOnClose
                            >

                                <Row>
                                    <div className="cart_box cart_out_box">
                                        <div className="summary_box">
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Instruction Id</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.instruction_id}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Confirmation Id</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.instruction_id}{ConfirmDetail.id}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Carrier</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.carrier}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                        <div className="summary_box">
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Tracking no.</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.tracking}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Ship-Pro reference</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.shippro_reference}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Delivered date</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.confirm_date}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                </Row>

                            </Drawer>
                        </div>
                    </>
                }
            />
        );
    }
}
export default withRouter(CartOut);
