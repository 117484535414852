import React, { useEffect } from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
  Row,
  Col,
  Table,
  Button,
  notification,
  Spin,
  Card,
  Input,
  message,
  DatePicker,
  Select,
  Tag,
  Breadcrumb,
  Drawer,
  Space,
  Divider,
  Modal,
  Upload
} from "antd";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";
import Form from "../../Helper/Form";
import { ExportOutlined, AppstoreAddOutlined, UploadOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { sessionData } from "../../Helper/Session";
const { Search } = Input;

class ShippingRequestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shipping_request: [],
      isLoadingRequestList: true,
      isSearching: false,
      track_data: [],
      customer_account: [],
      selectedShipProCustomerAccount: [],
      drawerPackagingVisible: false,
      isConfirmRecProductsLoading: false,
      visibleModalPackage: false,
      shipment_id: 0,
      shipmentProcessing_data: [],
      instruction_id: "",
      shipmentProcessing_data_count: 0,
      currency: [],
      shipment_processing_id: "",
      shipmentProcessing_data_all: [],
      pod_uploaded_id: 0, /*last uploaded pod id*/
      isShippingSaving: false,
      total_supplier_cost: []
    };
  }

  gotToAddSR = () => {
    this.props.history.push("/add-shipping-request");
  };
  goToGenerateReport = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const customer_account = queryParams.get("customer_account");
    const status = queryParams.get("status");
    const from_date = queryParams.get("from_date");
    const to_date = queryParams.get("to_date");
    const shipping_id = queryParams.get("shipping_id");
    let from_date_ = from_date ? moment(from_date).format("YYYY-MM-DD") : "";
    let to_date_ = to_date ? moment(to_date).format("YYYY-MM-DD") : "";
    let status_ = status ? status : "";
    window.location.href =
      `${BASE_URL}${API.GENERATE_REPORT}` +
      "?status=" +
      status_ +
      "&from_date=" +
      from_date_ +
      "&to_date=" +
      to_date_ +
      "&customer_account=" +
      customer_account;
  };
  formRef = React.createRef();
  formRefShip = React.createRef();

  componentDidMount() {
    this.setState({
      total_supplier_cost: {
        currency: "",
        total_supplier_cost: 0
      }
    })
    const queryParams = new URLSearchParams(window.location.search);
    const shipping_id = queryParams.get("shipping_id");
    const instruction_id = queryParams.get("instruction_id");
    if (shipping_id && instruction_id) {
      this.shipmentProcessing(shipping_id, instruction_id);
    }
    var selectedCAShipPro = localStorage.getItem('selectedCAShipPro');
    selectedCAShipPro = JSON.parse(selectedCAShipPro);
    if (selectedCAShipPro != null) {
      if (!selectedCAShipPro.selectedShipProCustomerAccount && !selectedCAShipPro.selectedShipProCustomerAccount) {
        notification.error({ message: 'Error', description: 'Customer account is not selected! Please select to proceed.', placement: "top" });
      } else {
        this.setState({
          selectedShipProCustomerAccount: selectedCAShipPro.selectedShipProCustomerAccount,
        }, () => {
          this.handleShippingList();
          this.shipmentProcessingFetchAll();
        });
      }
    } else {
      notification.error({ message: 'Error', description: 'Customer account is not selected! Please select to proceed.', placement: "top" });
    }


    fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_account: json.data,
          });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
    this.fetchCurrency();
  }
  handleShippingList = () => {

    this.setState({ isLoadingRequestList: true });
    fetch(`${BASE_URL}${API.SHIPPING_REQUEST_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        // status: status ? status : "",
        // from_date: from_date ? from_date : "",
        // to_date: to_date ? to_date : "",
        customer_account: this.state.selectedShipProCustomerAccount.id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadingRequestList: false });
        if (json.status == true) {
          this.setState({
            shipping_request: json.data,
          });
          // notification.success({
          //   message: json.success_msg,
          // });
        } else {
          notification.error({
            message: json.error_msg,
          });
        }
      });
  }
  handleTracking = (value) => {
    this.setState({ isSearching: true });
    fetch(`${BASE_URL}${API.SHIPMENT_TRACKING}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        tracking_id: value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isSearching: false });
        if (json.status == true) {
          this.setState({
            track_data: json.data,
          });
          this.props.history.push("/shipping-request/view/" + json.data.id);
        } else if (json.status == false) {
          message.error({
            content: json.error_msg,
            duration: 2,
          });
        } else {
          message.error({
            content: 'Data you requested was not found on the server!',
            duration: 2,
          });
        }
      });
  };

  shipmentProcessingFetch = () => {
    this.setState({ isSearching: true });
    fetch(`${BASE_URL}${API.SHIPMENT_PROCESSING}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipment_id: this.state.shipment_id,
        request_data: "partial",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isSearching: false });
        if (json.status == true) {
          this.setState({
            shipmentProcessing_data: json.data,
            shipmentProcessing_data_count: json.data.length,
            total_supplier_cost: json.total_supplier_cost
          });
        } else {
          this.setState({
            shipmentProcessing_data: [],
            shipmentProcessing_data_count: 0,
            total_supplier_cost: {
              currency: "",
              total_supplier_cost: 0
            }
          });
        }
      });
  };

  shipmentProcessingFetchAll = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get("status");
    const from_date = queryParams.get("from_date");
    const to_date = queryParams.get("to_date");
    const customer_account = queryParams.get("customer_account");
    this.formRef.current.setFieldsValue({
      status: status,
      from_date: from_date ? moment(from_date) : "",
      to_date: to_date ? moment(to_date) : "",
      // customer_account: customer_account,
    });
    this.setState({ isSearching: true });
    fetch(`${BASE_URL}${API.SHIPMENT_PROCESSING}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        customer_account: this.state.selectedShipProCustomerAccount.id,
        request_data: "all",
        from_date: from_date ? from_date : '',
        to_date: to_date ? to_date : ''
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isSearching: false });
        if (json.status == true) {
          this.setState({
            shipmentProcessing_data_all: json.data,
          });
        } else {
          this.setState({
            shipmentProcessing_data_all: [],
          });
        }
      });
  };

  fetchCurrency = () => {
    fetch(`${BASE_URL}${API.CURRENCY}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          currency: json.data,
        });
      });
  };

  onFinish = (value) => {
    value.customer_account = this.state.selectedShipProCustomerAccount.id;
    let from_date = value.from_date
      ? moment(value.from_date).format("YYYY-MM-DD")
      : "";
    let to_date = value.to_date
      ? moment(value.to_date).format("YYYY-MM-DD")
      : "";
    // let status = value.status ? value.status : "";
    let customer_account = value.customer_account ? value.customer_account : "";
    window.location.href =
      "/shipping-request-list/?from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&customer_account=" +
      customer_account;
  };

  shipmentProcessing = (id, instruction_id) => {
    this.setState({
      drawerPackagingVisible: true,
      shipment_id: id,
      instruction_id: instruction_id
    }, () => { this.shipmentProcessingFetch() })
  }

  addShipment = () => {
    this.setState({
      visibleModalPackage: true
    })
  }
  addShipmentLoad = (id) => {
    this.props.history.push(
      "/process-shipping/view/" + btoa(id)
    );
  }
  saveShipmentProcessing = (value) => {
    this.setState({ isShippingSaving: true });
    value.shipment_id = this.state.shipment_id;
    value.processing_id_serial = (this.state.shipmentProcessing_data_count + 1);
    // value.pod = this.state.pod_uploaded_id;
    value.processing_date = value.processing_date ? moment(value.processing_date).format("YYYY-MM-DD") : '';
    fetch(`${BASE_URL}${API.SAVE_SHIPMENT_PROCESSING}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(value),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({ visibleModalPackage: false });
          this.shipmentProcessingFetch();
          notification.success({ message: json.success_msg, placement: "top" });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
        this.setState({ isShippingSaving: false });
      });
  }

  render() {
    const {
      shipping_request,
      isLoadingRequestList,
      isSearching,
      customer_account,
      currency,
      selectedShipProCustomerAccount,
      drawerPackagingVisible,
      isConfirmRecProductsLoading,
      visibleModalPackage,
      shipment_id,
      shipmentProcessing_data,
      shipmentProcessing_data_all,
      isShippingSaving,
      total_supplier_cost
    } = this.state;
    const { Option } = Select;
    var data = [];
    const shipmentProcessing_data_col_all = [
      {
        title: "Shipment Processing ID",
        dataIndex: "processing_id",
      },
      {
        title: "Shipment Instruction ID",
        dataIndex: "instruction_id",
      },
      {
        title: "Processing type",
        dataIndex: "processing_type",
      },
      {
        title: "Processing date",
        dataIndex: "processing_date",
      },
      {
        title: "Item description",
        dataIndex: "item_description",
      },
      {
        title: "Service provider",
        dataIndex: "service_provider",
      },
      {
        title: "Provider order reference",
        dataIndex: "provider_order_reference",
      },
      // {
      //   title: "Tracking no",
      //   dataIndex: "tracking_no",
      // },
      // {
      //   title: "Tracking url",
      //   dataIndex: "tracking_url",
      // },
      // {
      //   title: "Supplier cost",
      //   dataIndex: "supplier_cost",
      // },
      // {
      //   title: "Supplier invoice reference",
      //   dataIndex: "supplier_invoice_reference",
      // },
      {
        title: "Customer so number",
        dataIndex: "customer_so_number",
      },
      {
        title: "SISL Invoice reference",
        dataIndex: "sisl_invoice_ref",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Action",
        dataIndex: "action",
      }
    ];
    const shipmentProcessing_data_col = [
      // {
      //   title: "SNo",
      //   dataIndex: "sno",
      // },
      {
        title: "Shipment Processing ID",
        dataIndex: "processing_id",
      },
      {
        title: "Shipment Instruction ID",
        dataIndex: "instruction_id",
      },
      {
        title: "Processing type",
        dataIndex: "processing_type",
      },
      {
        title: "Item description",
        dataIndex: "item_description",
      },
      {
        title: "Service provider",
        dataIndex: "service_provider",
      },
      {
        title: "Provider order reference",
        dataIndex: "provider_order_reference",
      },
      // {
      //   title: "Tracking no",
      //   dataIndex: "tracking_no",
      // },
      // {
      //   title: "Tracking url",
      //   dataIndex: "tracking_url",
      // },
      {
        title: "Customer so number",
        dataIndex: "customer_so_number",
      },
      {
        title: "SISL Invoice reference",
        dataIndex: "sisl_invoice_ref",
      },
      {
        title: "Supplier cost",
        dataIndex: "supplier_cost",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Action",
        dataIndex: "action",
      }
    ];
    const columns = [
      // {
      //   title: "SNo",
      //   dataIndex: "key",
      // },
      {
        title: "Shipment Instruction ID",
        dataIndex: "request_id",
      },
      {
        title: "Instruction date",
        dataIndex: "instruction_date",
      },
      {
        title: "Client Ref ID/ Client AWB No",
        dataIndex: "shipment_reference_id",
      },
      // {
      //   title: "Shipment Id",
      //   dataIndex: "shipment_id",
      // },
      // {
      //   title: "SO number",
      //   dataIndex: "so_number",
      // },
      {
        title: "Request created by",
        dataIndex: "customer_name",
      },
      {
        title: "Delivery Address",
        dataIndex: "ship_to",
      },
      {
        title: "Collection Address",
        dataIndex: "ship_from",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Process Shipping Instruction",
        dataIndex: "action",
      },
    ];
    if (sessionData.role_id == 4 || sessionData.role_id == 3) {
      columns.splice(6, 1);
      shipmentProcessing_data_col_all.splice(9, 1);
      shipmentProcessing_data_col_all.splice(6, 2);
    }
    var shipment_data = [];
    var shipment_data_all = [];
    shipmentProcessing_data.map((row, i) =>
      shipment_data.push({
        key: i + 1,
        processing_id: row.instruction_id + "-" + row.processing_id_serial,
        instruction_id: row.instruction_id,
        processing_type: row.processing_type,
        item_description: row.item_description,
        service_provider: row.service_provider,
        provider_order_reference: row.provider_order_reference,
        tracking_no: row.tracking_no,
        tracking_url: row.tracking_url,
        pod: row.pod,
        status: (<Tag color={(row.status == "Completed" ? "green" : "yellow")}>{row.status}</Tag>),
        supplier_cost: row.supplier_cost,
        supplier_invoice_reference: row.supplier_invoice_reference,
        customer_so_number: row.sisl_so_number,
        sisl_invoice_ref: row.sisl_invoice_reference,
        created_by: row.created_by,
        supplier_cost: row.supplier_currency + " " + row.supplier_cost,
        action: (<Button
          type="warning"
          size="small"
          onClick={() => { this.addShipmentLoad(row.id) }}
          icon={<EditOutlined />}
        />)
      })
    );
    shipmentProcessing_data_all.map((row, i) =>
      shipment_data_all.push({
        key: i + 1,
        processing_id: row.instruction_id + "-" + row.processing_id_serial,
        processing_date: row.processing_date ? moment(row.processing_date).format("DD-MM-YYYY") : '',
        instruction_id: row.instruction_id,
        processing_type: row.processing_type,
        item_description: row.item_description,
        service_provider: row.service_provider,
        provider_order_reference: row.provider_order_reference,
        tracking_no: row.tracking_no,
        tracking_url: row.tracking_url,
        pod: row.pod,
        status: (<Tag color={(row.status == "Completed" ? "green" : "yellow")}>{row.status}</Tag>),
        supplier_cost: row.supplier_cost,
        supplier_invoice_reference: row.supplier_invoice_reference,
        customer_so_number: row.sisl_so_number,
        sisl_invoice_ref: row.sisl_invoice_reference,
        created_by: row.created_by,
        action: (<Button
          type="warning"
          size="small"
          onClick={() => { this.addShipmentLoad(row.id) }}
          icon={<EditOutlined />}
        />)
      })
    );
    shipping_request.map((sr, i) =>
      data.push({
        key: i + 1,
        request_id: (<Button
          type="link"
          size="small"
          onClick={() => {
            this.props.history.push(
              "/shipping-request/view/" + sr["id"]
            );
          }}
        >{sr["request_id"]}</Button>),
        instruction_date: (sr["awb_collection_date"]) ? moment(sr["awb_collection_date"]).format("DD-MM-YYYY") : '',
        shipment_reference_id: sr["client_reference_id"],
        shipment_id:
          sr["request_id"] +
          (sr["shipment_id"] ? "-" + sr["shipment_id"] : "") +
          (sr["is_reverse"] == "yes" ? "-REV" : ""),
        so_number: sr["so_number"],
        ship_from:
          sr["is_reverse"] == "yes"
            ? sr["to_street"] +
            ", " +
            sr["to_city"] +
            ", " +
            sr["to_state"] +
            ", " +
            sr["to_country"] +
            " " +
            (sr["to_postal"] ? " - " + sr["to_postal"] : "")
            : sr.from_street +
            ", " +
            sr.from_city +
            ", " +
            sr.from_state +
            ", " +
            sr.from_country +
            " " +
            (sr.from_postal ? " - " + sr.from_postal : ""),
        ship_to:
          sr["is_reverse"] == "yes"
            ? sr.from_street +
            ", " +
            sr.from_city +
            ", " +
            sr.from_state +
            ", " +
            sr.from_country +
            " " +
            (sr.from_postal ? " - " + sr.from_postal : "")
            : sr["to_street"] +
            ", " +
            sr["to_city"] +
            ", " +
            sr["to_state"] +
            ", " +
            sr["to_country"] +
            " " +
            (sr["to_postal"] ? " - " + sr["to_postal"] : ""),
        customer_name: sr["customer_name"],
        status: (
          <>
            {sr.status == 1 && (
              <Tag color="#002140">Instruction Received</Tag>
            )}
            {sr.status == 2 && (
              <Tag color="#e2ca10">Shipment In Progress</Tag>
            )}
            {sr.status == 3 && (
              <Tag color="#019b04">Shipment Completed</Tag>
            )}
          </>
        ),
        action: (
          <Button
            type="secondary"
            size="small"
            onClick={() => this.shipmentProcessing(sr.id, sr.request_id)} >
            Process
          </Button>
        ),
      })
    );

    const props = {
      action: `${BASE_URL}${API.UPLOAD_DOCS}`,
      multiple: false,
      maxCount: 1,
      headers: {
        Accept: "application/form-data",
        Authorization: "Bearer " + token,
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          // console.log(info.file, info.fileList);
          if (info.file.response.status == true && info.file.status == "done") {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.response.status == false || info.file.status == "error") {
            message.error(info.file.response.error_msg + " or " + info.file.name + "file upload failed.");
          }
        }
      },
      // onRemove(v) {
      //   console.log(v);
      // },
    };
    return (
      <Headers
        bodyContain={
          <>
            {/* <Row>
              <Col md={24} sm={24} xs={24}>
                <Card style={{ marginBottom: "5px" }}>

                  <Row style={{ justifyContent: "space-between" }}>
                    <Col md={16} className="p5">
                      <Search
                        type="secondary"
                        placeholder="Enter shipment Id"
                        enterButton="Search"
                        size="medium"
                        loading={isSearching}
                        onSearch={this.handleTracking}
                      />
                      <span>
                        Please enter shipment id to search the shipment.
                      </span>
                    </Col>

                  </Row>
                </Card>
              </Col>
            </Row> */}
            <Row>
              <Col md={18}>
                <Breadcrumb style={{ fontSize: "15px", padding: "12px 0px" }}>
                  <Breadcrumb.Item style={{ color: "#000" }}>{selectedShipProCustomerAccount.customer_account_name}</Breadcrumb.Item>
                  <Breadcrumb.Item>Shipment Instruction List</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col>
                <Button
                  onClick={this.gotToAddSR} ///{this.gotToAddSR}
                  type="primary"
                  style={{ marginBottom: "10px" }}
                  shape="round"
                // size="large"
                >
                  Create Shipment Instruction
                </Button>
              </Col>
            </Row>
            {/* <h3 className="title">Shipping Request List</h3> */}
            <div className="site-layout-background">
              <Card>
                <Spin spinning={isLoadingRequestList}>
                  <Row>
                    <Col md={24}>
                      <Table
                        columns={columns}
                        dataSource={data}
                        size={"small"}
                        bordered
                        loading={isLoadingRequestList}
                      />
                    </Col>
                  </Row>
                </Spin>
              </Card>
              <Card>
                <h3>Shipment Processing List</h3>
                <Row style={{ "marginBottom": "10px" }}>
                  <Col md={18}>
                    <Form
                      name="search_filter"
                      layout="horizontal"
                      onFinish={this.onFinish}
                      scrollToFirstError
                      ref={this.formRef}
                    >
                      <Row>
                        <Col className="filter">
                          <span> From Date</span>
                          <Form.Item name="from_date">
                            <DatePicker format="DD-MM-YYYY" size="small" />
                          </Form.Item>
                        </Col>
                        <Col className="filter">
                          <span>To Date</span>
                          <Form.Item name="to_date">
                            <DatePicker format="DD-MM-YYYY" size="small" />
                          </Form.Item>
                        </Col>
                        <Col className="" style={{ padding: "40px 5px" }}>
                          <Button
                            htmlType="submit"
                            type="primary"
                            // style={{ marginTop: "20px", }}
                            size="small"
                          >
                            Search
                          </Button>
                          <Button size="small" style={{ marginLeft: "3px" }} onClick={() => window.location.href = '/shipping-request-list'}>Reset</Button>
                        </Col>

                      </Row>
                    </Form>
                  </Col>

                  <Col>
                    {(sessionData.role_id == 1 || sessionData.role_id == 2 || sessionData.role_id == 5) && (
                      <Button
                        onClick={() => window.location.href = BASE_URL + API.GENERATE_SHIPPING_INSTRUCTION_REPORT + "?property=" + btoa("all_user") + "&token=" + token + "&from_date=" + (this.formRef.current.getFieldValue('from_date') ? this.formRef.current.getFieldValue('from_date').format("YYYY-MM-DD") : '') + "&to_date=" + (this.formRef.current.getFieldValue('to_date') ? this.formRef.current.getFieldValue('to_date').format("YYYY-MM-DD") : '') + "&customer_account=" + selectedShipProCustomerAccount.id}
                        type="primary"
                        style={{ marginLeft: "3px" }}
                        icon={<ExportOutlined />}
                        size=""
                      >
                        Generate Shipment Processing Report
                      </Button>
                    )}
                    {/* {(sessionData.role_id == 4) && (
                      <Button
                        onClick={() => window.location.href = BASE_URL + API.GENERATE_SHIPPING_INSTRUCTION_REPORT + "?property=" + btoa("customer") + "&token=" + token}
                        type="primary"
                        style={{ marginLeft: "3px" }}
                        icon={<ExportOutlined />}
                        size=""
                      >
                        Generate Shipment Processing Report
                      </Button>
                    )} */}
                  </Col>
                </Row>
                <Spin spinning={isLoadingRequestList}>
                  <Row>
                    <Col md={24}>
                      <Table
                        columns={shipmentProcessing_data_col_all}
                        dataSource={shipment_data_all}
                        size={"small"}
                        bordered
                        loading={isLoadingRequestList}
                      />
                    </Col>
                  </Row>
                </Spin>
              </Card>
              <Drawer title={"Shipment Instruction ID - " + this.state.instruction_id} placement="right" closable={true}
                mask={true}
                width={1100}
                visible={this.state.drawerPackagingVisible}
                onClose={() => { this.setState({ drawerPackagingVisible: false }) }}
                destroyOnClose
                extra={
                  <Space>
                    <Button onClick={() => { this.setState({ drawerPackagingVisible: false }) }}>Cancel</Button>
                  </Space>
                }>

                <Table
                  columns={shipmentProcessing_data_col}
                  dataSource={shipment_data}
                  size="small"
                  bordered
                  loading={isSearching}
                  pagination={false}
                  footer={() => (<div style={{ "textAlign": "right" }}>Total Supplier Cost: {total_supplier_cost.currency + " " + total_supplier_cost.total_supplier_cost}</div>)}
                />
                <Divider />
                <Row gutter={16}>
                  <Col md={24}>
                    <Button type="primary" size="small" onClick={this.addShipment} style={{ width: "100%" }} icon={<AppstoreAddOutlined />}>
                      Add Shipment Processing ID {this.state.instruction_id + "-" + (this.state.shipmentProcessing_data_count + 1)}
                    </Button>
                  </Col>
                </Row>
              </Drawer>
              <Modal
                style={{ top: 20 }}
                visible={visibleModalPackage}
                title={"Add Shipment Processing ID : " + this.state.instruction_id + "-" + (this.state.shipmentProcessing_data_count + 1)}
                onCancel={() => {
                  this.setState({ visibleModalPackage: false });
                }}
                destroyOnClose={true}
                footer={[
                  <Space>
                    <Button
                      onClick={() => {
                        this.setState({ visibleModalPackage: false });
                      }}
                    >
                      Cancel
                    </Button>
                  </Space>
                ]}
                width={1000}
              >
                <div className="form-design">
                  <Form
                    name="formShip"
                    layout="vertical"
                    onFinish={this.saveShipmentProcessing}
                    scrollToFirstError
                    ref={this.formRefShip}
                  >
                    <Row>
                      <Col md={6}>
                        <Form.Item
                          name="processing_type"
                          label="Processing Type"
                          rules={[
                            {
                              required: true,
                              message: "Please choose Processing Type!",
                            },
                          ]}
                        >
                          <Select size="small">
                            <Option value="Packaging">Packaging</Option>
                            <Option value="Freight">Freight</Option>
                            <Option value="Duties / Import Taxes">Duties / Import Taxes</Option>
                            <Option value="Other charges">Other charges</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="item_description"
                          label="Item description"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Item description!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="service_provider"
                          label="Service Provider"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Service Provider!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="provider_order_reference"
                          label="Provider order reference"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Provider order reference!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="tracking_no"
                          label="Tracking"
                          rules={[
                            {
                              required: false,
                              message: "Please enter tracking!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          name="tracking_url"
                          label="Tracking URL"
                          rules={[
                            {
                              required: false,
                              message: "Please enter tracking url!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="pod"
                          label="POD"
                          rules={[
                            {
                              required: false,
                              message: "Please select POD!",
                            },
                          ]}
                        >
                          <Upload
                            size="small"
                            {...props}
                            data={{
                              instruction_id: this.state.instruction_id,
                              shipment_id: shipment_id,
                              processing_id: (this.state.shipmentProcessing_data_count + 1),
                              doc_type: "pod",
                            }}
                          >
                            <Button icon={<UploadOutlined />}>
                              Click to Upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="status"
                          label="Status"
                          rules={[
                            {
                              required: false,
                              message: "Please select status!",
                            },
                          ]}
                        >
                          <Select size="small">
                            <Option value="InProgress">Processing in Progress</Option>
                            <Option value="Completed">Processing Completed</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="processing_date"
                          label="Processing Date"
                          rules={[
                            {
                              required: false,
                              message: "Please enter processing date!",
                            },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }}
                            // onChange={this.onChange}
                            format="DD-MM-YYYY" />
                        </Form.Item>
                      </Col>
                      <Divider orientation="left">SISL Information</Divider>
                      <Col md={6}>
                        <Form.Item
                          name="billing_information"
                          label="SISL Billing Information"
                          rules={[
                            {
                              required: false,
                              message: "Please enter SISL billing information!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="sisl_so_number"
                          label="SISL SO number"
                          rules={[
                            {
                              required: false,
                              message: "Please enter SISL SO number!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="sisl_invoice_reference"
                          label="SISL Invoice Reference"
                          rules={[
                            {
                              required: false,
                              message: "Please enter SISL Invoice Reference!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="sisl_invoice_currency"
                          label="SISL Invoice currency"
                          rules={[
                            {
                              required: false,
                              message: "Please enter SISL Invoice currency!",
                            },
                          ]}
                        >
                          <Select size="small" showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {currency.map((opt) => {
                              return <Option value={opt.id}>{opt.currency_name}</Option>;
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="sisl_invoice_amount"
                          label="SISL Invoice amount"
                          rules={[
                            {
                              required: false,
                              message: "Please enter SISL Invoice amount!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Divider orientation="left">Supplier Information</Divider>
                      <Col md={6}>
                        <Form.Item
                          name="supplier_currency"
                          label="Supplier Currency"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Supplier Currency!",
                            },
                          ]}
                        >
                          <Select size="small" showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {currency.map((opt) => {
                              return <Option value={opt.id}>{opt.currency_name}</Option>;
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="supplier_cost"
                          label="Supplier cost"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Supplier cost!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="supplier_invoice_reference"
                          label="Supplier invoice reference"
                          rules={[
                            {
                              required: false,
                              message: "Please select status!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Item>
                          <Button type="primary" htmlType="submit" size="medium" loading={isShippingSaving}>
                            Save Shipment Processing ID
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Modal>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(ShippingRequestList);
